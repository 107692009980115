import React, { useState, useEffect, useMemo } from "react";
import { instance } from "../../axios";
import { UiSelect, UiToggle, PageError, UiActioButton, TableZero, UiRSelect, BgLoader, InLoader, UiButton } from "../../ui";
import { HourMin } from "../../helper";

import * as yup from "yup"; import { NavLink, useNavigate, useParams, useLocation, Outlet, useOutletContext } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import DataTable from 'react-data-table-component';

import { GoPlus } from "react-icons/go";
import { tr } from "date-fns/locale";


export default function TimeLog() {
  const [pstate, setPSate] = useOutletContext();

  const [state, setState] = useState({});
  const [inner_loader, setInnerLoader] = useState(false);

  const userModal = useModal('log-time');


  const showAddModal = (row) => {

    userModal.show({ data: row, taskid: pstate.task.id }).then((res) => {
      fetchData();
    });


  }

  const handleDelete = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        setInnerLoader(true)
        instance
          .get("/update_timelog?delete=" + id)
          .then(function (response) {
            setInnerLoader(false)
            if (response.data.status == 'success') {
              toast(response.data.msg, { type: "success" });
            } else {
              toast(response.data.msg, { type: "error" });
            }

            fetchData();

          })

      }
    })


  }
  const columns = [
    {
      name: 'Work',
      selector: row => row.work,
      sortable: true,
      cell: row => (<b className="text-dark">{row.work}</b>)

    },
    {
      name: 'User',
      sortable: true,
      selector: row => row.name,
      cell: row => (row.name)
    },
    {
      name: 'Date',
      sortable: true,
      selector: row => row.date,
      cell: row => (row.date)
    },
    {
      name: 'Time Range',
      sortable: true,
      selector: row => row.start,
      cell: row => (<span>{row.start} - {row.end}</span>)
    },
    {
      name: 'Time Spent',
      sortable: true,
      selector: row => row.hours,
      cell: row => (HourMin(row.hours))
    },

    {
      name: '',
      selector: row => row.id,
      width: (window.innerWidth > 768 ? '0px' : '130px'),
      cell: row => (<>
        {row.can_delete ? (
          <div className="tableaction">
            {state.manual_perm == 1 &&
              <UiActioButton title="Edit" action={() => showAddModal(row)} />
            }
            <UiActioButton title="Delete" action={() => handleDelete(row.id)} />

          </div>

        ) : ('')}
      </>
      )
    },
  ];


  const fetchData = () => {
    setInnerLoader(true)
    instance
      .get("/timelog_list?id=" + pstate.task.id)
      .then(function (response) {
        setInnerLoader(false)
        setState({ ...state, ...response.data });


      })
      .catch(function (error) { })
      .then(function () { });

  }


  useEffect(() => {
    fetchData();
  }, []);


  if (state.status == 'error') {
    return (
      <PageError msg={state.msg} code={state.code} />
    )
  }


  return (<>
    {state.timelogs ? (
      <div className="card card-default">
        {inner_loader == true && <InLoader />}
        <div className="card-body">
          <div className="d-flex justify-content-between flex-column flex-sm-row">
            <div className="me-2 mb-2 mb-sm-0">
            <UiButton className="btn btn-primary" onClick={() => showAddModal()} icon="add" title="Log Time" disabled={state.manual_perm == 1 ? false : true}></UiButton>
            </div>
            {state.timelogs && state.timelogs.length > 0 && (
              <span><span className="text-muted">Total Time Spent:</span> <b>{HourMin(state.timelogs.reduce((acc, o) => acc + parseInt(o.hours), 0))}</b></span>
            )}

          </div>


        </div>
        <div className="card-body pt-0 tablecard">

          <DataTable
            columns={columns}
            data={state.timelogs}
            noDataComponent={<TableZero title="Time Logs" />}
          />


        </div>
      </div>
    ) : <BgLoader />}
  </>
  )

}

