import React, { useState, useEffect, useMemo } from "react";
import { instance } from "../../axios";
import { UiSelect, UiToggle, UiInput, UiActionDropDown, TableZero, UiButton, UiStatusChange, BgLoader, InLoader, PageHeader, UiActioButton, UiTagDisplay, UiAvatarGroup, Avatar, PageError, OverlayLoader } from "../../ui";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup"; import { NavLink, useNavigate, useParams, Link, Outlet, useOutletContext } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { format, isValid, parse } from 'date-fns';
import { Helmet } from "react-helmet";
import { useQueryClient } from 'react-query';
import formatRelative from 'date-fns/formatRelative'
import { useStore } from "../../state.js";
import enGB from 'date-fns/locale/en-GB';
import { MdAccessTime, MdOutlineArrowForwardIos, MdOutlineDragIndicator } from 'react-icons/md'
import { GoCheck } from 'react-icons/go'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { MyStopwatch } from "../../timer.js"
import { IoTimeOutline } from "react-icons/io5"
import { BsCloudCheck, BsTelephoneOutboundFill, BsWhatsapp } from "react-icons/bs";
import { DropdownItem } from '@atlaskit/dropdown-menu';
import { RiDraggable } from "react-icons/ri";
import { arrayMove } from 'react-sortable-hoc';
import { MentionsInput, Mention } from 'react-mentions'
import { LuWorkflow } from "react-icons/lu";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from 'react-sortable-hoc';


const SortableContainer = sortableContainer(({ children }) => {
  return <div>{children}</div>;
});

const DragHandle = sortableHandle(() => <span className="h3 pe-1 d-flex mb-0 text-secondary c-pointer"><RiDraggable /></span>);

const SortableItem = sortableElement((value) => {
  return (
    <div className={`d-flex align-items-center p-2 rounded mb-2 ${value.workflow == 1 ? 'border bg-white' : ' bg-light'}`}>
      <DragHandle />
      <div className="w-100 d-flex" {...value} />
    </div>
  )
});

const formatRelativeLocale = {
  lastWeek: "'Last' eeee",
  yesterday: "'Yesterday at ' hh:mm a",
  today: "'Today at' hh:mm a",
  tomorrow: "'Tomorrow at ' hh:mm a",
  nextWeek: "'Next' eeee",
  other: 'dd-MM-yyyy',
};

const locale = {
  ...enGB,
  formatRelative: (token) => formatRelativeLocale[token],
};


export function Notes() {
  const [pstate, setPState] = useOutletContext();

  const [state, setState] = useState({});
  const [inner_loader, setInnerLoader] = useState(false);
  const schema = yup.object().shape(
    {
      note: yup.string().required("Note is required").max(800, 'Maximum character limit is 800'),

    }
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });


  const watchNote = watch('note')


  const onSubmitHandler = async (data) => {
    setState({ ...state, loader: true });
    //var form = document.querySelector("#noteentryfrm");
    var data = new FormData();
    data.append('task', pstate.task.id);
    data.append('note', watchNote);

    instance({
      method: "post",
      url: "/update_note",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        setState({ ...state, loader: false });
        if (response.data.status == "error") {
          toast(response.data.msg, { type: "error" });
        }
        if (response.data.status == "success") {
          toast(response.data.msg, { type: "success" });
          fetchData();
          reset()
        }
      })
      .catch(function (response) { });
  };



  const fetchData = () => {
    setInnerLoader(true)
    instance
      .get("/get_notes?id=" + pstate.task.id)
      .then(function (response) {
        setInnerLoader(false)
        if (response.data.status == 'success') {
          setState({ ...state, ...response.data });
        } else {
          // toast(response.data.msg, { type: "error" });
        }


      })


  }

  useEffect(() => {
    fetchData();
  }, []);


  const handleDelete = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        setInnerLoader(true)
        instance
          .get("/update_note?delete=" + id)
          .then(function (response) {
            setInnerLoader(false)
            if (response.data.status == 'success') {
              fetchData();
              toast(response.data.msg, { type: "success" });
            } else {
              toast(response.data.msg, { type: "error" });
            }


          })

      }
    })


  }


  return (
    <div>
      <form id="noteentryfrm" onSubmit={handleSubmit(onSubmitHandler)} noValidate>



        <Controller
          name="note"
          control={control}
          defaultValue=''
          render={({
            field: { onChange, onBlur, value, name, ref },
            fieldState: { invalid, isTouched, isDirty, error },
            formState,
          }) => (

            <MentionsInput name={name} ref={ref} placeholder={"Type your message..."} className="mentioninput" value={value} onChange={(e) => { onChange(e.target.value) }} >
              <Mention
                displayTransform={(url, a) => `@${a}`}
                //markup="@(__id__)[__display__]"
                className="mentionlist"
                trigger="@"
                data={state.users}
                appendSpaceOnAdd={true}
                style={{ backgroundColor: 'var(--bs-highlight-bg)' }}
              // renderSuggestion={this.renderUserSuggestion}
              />

            </MentionsInput>

          )}
        />
        <small className="text-muted mb-2">Mention user using @</small>



        <div className="mb-4 justify-content-end d-flex">
          <UiButton className="btn btn-outline-primary" loading={state["loader"]} title="Post" disabled={(pstate.can_edit == true && watchNote && watchNote.length > 0) ? false : true} />
        </div>

      </form>
      {state.notes ? (
        <div className="position-relative">
          {inner_loader && <InLoader />}
          {state.notes.map((val, index) => (
            <div key={index} className="border-top py-3">
              <p style={{ whiteSpace: 'pre-line' }} dangerouslySetInnerHTML={{ __html: val.note }} />
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 d-flex align-items-center">
                  <OverlayTrigger overlay={<Tooltip >{val.name}</Tooltip>} >
                    <span className="d-inline-block">
                      <Avatar name={val.name} className="bg-secondary" src={val.photo} size="30" />
                    </span>
                  </OverlayTrigger>
                  <small className="ms-2 me-4 text-muted">{val.name}</small>
                  <small><MdAccessTime /> <span className="text-muted">{formatRelative(parse(val.created_on, "yyyy-MM-dd HH:mm:ss", new Date()), new Date(), { locale })}</span></small>
                </div>
                {val.can_delete == 1 && <UiButton className="btn btn-outline-danger btn-sm" onClick={() => handleDelete(val.id)} title="" icon="delete"></UiButton>}
              </div>


            </div>
          ))


          }
        </div>
      ) : <BgLoader />}

    </div>
  )

}





export function TaskCheckList() {
  const [pstate, setPState] = useOutletContext();

  const [state, setState] = useState({});

  const fetchData = () => {
    instance
      .get("/get_checklist?id=" + pstate.task.id)
      .then(function (response) {
        if (response.data.status == 'success') {
          setState({ ...state, ...response.data });
        } else {
          // toast(response.data.msg, { type: "error" });
        }
      })
  }

  useEffect(() => {
    fetchData();
  }, []);



  const completeStep = (idd, action) => {
    var arr = state.checklist.slice()
    arr.filter(x => x.id == idd)[0].loading = true;
    setState({ ...state, checklist: arr })
    instance
      .get("/complete_checklist?id=" + idd + "&action=" + action)
      .then(function (response) {
        fetchData();
        if (response.data.status == 'success') {

          toast(response.data.msg, { type: "success" });
        } else {
          toast(response.data.msg, { type: "error" });
        }


      })
  }

  return (
    <div>
      {state.checklist ? (
        <>

          <div className="w-100">
            <div className="">
              <div className="progress">
                <div className="progress-bar" role="progressbar" style={{ width: state.progress + "%" }} aria-valuenow={state.progress} aria-valuemin="0" aria-valuemax="100"></div>
              </div>
            </div>
            <div className="border-start pt-3 ps-0 ms-2 ms-sm-4">
              <ul className="list-group list-group-flush">
                {state.checklist.map((val, index) => (
                  <li className={`list-group-item ${val.loading ? 'opacity-25' : ''}`} key={index}>
                    <div className="d-flex justify-content-between">
                      <div>
                        <div className="form-check">
                          {val.completed == 1 ? (
                            <input className={`form-check-input me-1 ${val.not_applicable == 1 ? 'bg-secondary border-secondary' : ''}`} type="checkbox" onChange={() => completeStep(val.id, 0)} name="listGroupRadio" id={`cl${index}`} checked="checked" disabled={pstate.can_edit == true ? false : true} />
                          ) : (
                            <input className="form-check-input me-1" type="checkbox" onChange={() => completeStep(val.id, 1)} name="listGroupRadio" id={`cl${index}`} checked="" disabled={pstate.can_edit == true ? false : true} />
                          )}
                          <div className="ms-2">
                            <label className={`form-check-label d-block ${val.not_applicable == 1 ? ' opacity-75' : ''}`} htmlFor={`cl${index}`}>
                              {val.title}
                            </label>
                            {val.completed == 1 && (


                              <>

                                <div className="d-flex align-items-center mt-2">
                                  <div className="flex-grow-1 d-flex align-items-md-center flex-column flex-sm-row">
                                    <div className="mb-2 mb-sm-0">
                                      <OverlayTrigger overlay={<Tooltip >{val.name}</Tooltip>} >
                                        <span className="d-inline-block">
                                          <Avatar name={val.name} className="bg-secondary" src={val.photo} size="20" />
                                        </span>
                                      </OverlayTrigger>
                                      <small className="ms-2 me-4 text-muted">{val.name}</small>
                                    </div>
                                    <small><MdAccessTime /> <span className="text-muted">{formatRelative(parse(val.completed_on, "yyyy-MM-dd HH:mm:ss", new Date()), new Date(), { locale })}</span></small>
                                  </div>

                                </div>




                              </>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="tableaction1">
                        <UiActionDropDown newclassName="btn tablebtn" >
                          <DropdownItem onClick={() => completeStep(val.id, val.not_applicable == 1 ? 0 : 2)} isDisabled={pstate.can_edit == true ? false : true}>{val.not_applicable == 1 ? 'Mark as applicable' : 'Mark as not applicable'}</DropdownItem>

                        </UiActionDropDown>
                      </div>
                    </div>
                  </li>

                ))

                }



              </ul></div>
          </div>
        </>
      ) : <BgLoader />}

    </div>
  )

}




export function TaskSubtask() {
  const [pstate, setPState] = useOutletContext();

  const [state, setState] = useState({});


  const statusModal = useModal('task-status');
  const duedateModal = useModal('task-duedate');
  const userModal = useModal('taskusers');




  const fetchData = () => {
    instance
      .get("/get_subtasks?id=" + pstate.task.id)
      .then(function (response) {
        if (response.data.status == 'success') {
          setState({ ...state, ...response.data });
        }
      })
  }

  useEffect(() => {
    fetchData();
  }, []);



  const subtaskModal = useModal('add-subtask');

  const AddSubtask = (id, st_id) => {
    subtaskModal.show({ id: id, st_id: st_id }).then((res) => {
      fetchData();
    });

  }


  const showstatusModal = (id) => {
    if (id.length == 1) {
      var tid = id[0].id;
      var val = id[0].status;
    } else if (id.length > 1) {
      var col0 = id.map(d => d['id']);
      var tid = col0.toString();
      var val = null;
    }
    statusModal.show({ task_id: tid, tval: val }).then((res) => {
      fetchData();

    });

  }

  const showduedateModal = (id) => {
    if (id.length == 1) {
      var tid = id[0].id;
      var val = id[0].due_date;
    } else if (id.length > 1) {
      var col0 = id.map(d => d['id']);
      var tid = col0.toString();
      var val = null;
    }
    duedateModal.show({ task_id: tid, duedate: val }).then((res) => {
      fetchData();
    });

  }

  const showuserModal = (id) => {
    if (id.length == 1) {
      var tid = id[0].id.toString();

    } else if (id.length > 1) {
      var col0 = id.map(d => d['id']);
      var tid = col0.toString();

    }
    userModal.show({ task_id: tid }).then((res) => {
      fetchData();
    });

  }


  const showverifyModal = (id) => {
    if (id.length == 1) {
      var tid = id[0].id.toString();

    } else if (id.length > 1) {
      var col0 = id.map(d => d['id']);
      var tid = col0.toString();

    }
    var arr = state.subtasks.slice()
    arr.filter(x => x.id == tid)[0].loading = true;
    setState({ ...state, subtasks: arr })

    instance({
      method: "GET",
      url: "/verify_task?id=" + tid + '&action=1',
    })
      .then(function (response) {
        if (response.data.status == "error") {
          toast(response.data.msg, { type: "error" });
        }
        if (response.data.status == "success") {
          toast(response.data.msg, { type: "success" });
          fetchData();
        }
      })
  }


  const handleDelete = (id) => {
    if (id.length == 1) {
      var tid = id[0].id.toString();

    } else if (id.length > 1) {
      var col0 = id.map(d => d['id']);
      var tid = col0.toString();

    }

    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        var arr = state.subtasks.slice()
        arr.filter(x => x.id == tid)[0].loading = true;
        setState({ ...state, subtasks: arr })
        instance
          .get("/delete_task?delete=" + tid)
          .then(function (response) {

            if (response.data.status == 'success') {
              fetchData();
              toast(response.data.msg, { type: "success" });
            } else {
              toast(response.data.msg, { type: "error" });
            }
          })
      }
    })
  }


  const handleDeleteWorkflow = (id) => {
    if (id.length == 1) {
      var tid = id[0].id.toString();

    } else if (id.length > 1) {
      var col0 = id.map(d => d['id']);
      var tid = col0.toString();

    }

    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        var arr = state.subtasks.slice()
        arr.filter(x => x.id == tid)[0].loading = true;
        setState({ ...state, subtasks: arr })
        instance
          .get("/update_subtask?delete=" + tid)
          .then(function (response) {

            if (response.data.status == 'success') {
              fetchData();
              toast(response.data.msg, { type: "success" });
            } else {
              toast(response.data.msg, { type: "error" });
            }
          })
      }
    })
  }

  const onSortEnd = ({ oldIndex, newIndex }) => {
    subtaskAction(pstate.task.id, 'order_update', oldIndex + '-' + newIndex);
    var newsort = arrayMove(state.subtasks, oldIndex, newIndex);
    setState({ ...state, subtasks: newsort });
  };
  const subtaskAction = (idd, action, order) => {
    if (action != 'order_update') {

    }
    instance
      .get("/task_subtask_action?task_id=" + idd + "&action=" + action + "&order=" + order)
      .then(function (response) {
        //fetchData();
        if (response.data.status == 'success') {
          if (action == 'enable_workflow') {
            setPState({ ...pstate, task: { ...pstate.task, subtask_workflow: 1 } });
          } else if (action == 'disable_workflow') {
            setPState({ ...pstate, task: { ...pstate.task, subtask_workflow: 0 } });
          }
          toast(response.data.msg, { type: "success" });
        } else {
          toast(response.data.msg, { type: "error" });
        }


      })
  }

  return (
    <div className="card card-default mb-4">
      <div className="card-header">
        <div className="d-flex justify-content-between">
          <div>Subtasks</div>
          {pstate.can_edit == true && (
            <UiActioButton title="Add" tooltip="Create New Subtask" action={() => AddSubtask(pstate.task.id)}></UiActioButton>
          )}
        </div>
      </div>
      <div className="card-body">
        {state.subtasks && state.subtasks.length > 0 ? (
          <>

            <div className="w-100">
              <SortableContainer onSortEnd={onSortEnd} useDragHandle>
                {state.subtasks.map((val, index) => (
                  <SortableItem key={index} index={index} disabled={index == 1 ? 1 : 0} workflow={val.workflow} >
                    <div key={index} className="d-flex justify-content-between align-items-center w-100">
                      <div className="w-100">
                        <div className="row">
                          <div className="col">
                            {val.workflow == 1 ? val.title : <Link to={`/view-task/${val.id}`}>{val.title}</Link>}
                          </div>
                          <div className="col-auto">
                            {val.workflow == 1 ? <span className={`badge bg-secondary`}>To be created</span>
                              :
                              <span className={`badge ${val.status}`}>{val.status}</span>
                            }
                          </div>

                        </div>
                        <div className="row align-items-center ">
                          <div className="col">{val.due_date && <span className="small text-secondary mt-2">Due Date: {val.due_date}</span>}</div>
                          <div className="col-auto">{val.users && <div className="mt-2"><UiAvatarGroup items={val.users}></UiAvatarGroup></div>}</div>

                        </div>
                      </div>
                      <div className="tableaction1">
                        {val.workflow == 1 ?
                          <UiActionDropDown newclassName="btn tablebtn" >
                            <DropdownItem isDisabled={pstate.can_edit == 1 ? false : true} onClick={() => AddSubtask(pstate.task.id, val.id)}>Edit</DropdownItem>
                            <DropdownItem className="text-danger" isDisabled={pstate.can_edit == 1 ? false : true} onClick={() => handleDeleteWorkflow([val])}>Delete</DropdownItem>

                          </UiActionDropDown>
                          :
                          <UiActionDropDown newclassName="btn tablebtn" >
                            <DropdownItem isDisabled={pstate.can_edit == 1 && val.verified == 'No' ? false : true} onClick={() => showstatusModal([val])}>Change Status</DropdownItem>
                            <DropdownItem isDisabled={pstate.can_edit == 1 && val.verified == 'No' ? false : true} onClick={() => showduedateModal([val])}>Change Due Date</DropdownItem>
                            <DropdownItem isDisabled={val.can_assign == 1 && val.verified == 'No' ? false : true} onClick={() => showuserModal([val])}>Assign Users</DropdownItem>
                            <DropdownItem isDisabled={val.can_verify == 1 && val.verified == 'No' && val.status == 'Completed' ? false : true} onClick={() => showverifyModal([val])}>Verify</DropdownItem>
                            <DropdownItem className="text-danger" isDisabled={pstate.can_edit == 1 ? false : true} onClick={() => handleDelete([val])}>Delete</DropdownItem>

                          </UiActionDropDown>
                        }
                      </div>
                    </div>
                  </SortableItem>
                ))}
              </SortableContainer>

            </div>
          </>
        ) : state.subtasks && state.subtasks.length == 0 ? <TableZero title="Subtasks" /> : <BgLoader />}

      </div>
    </div>
  )

}




export function TaskDetails() {
  const [pstate, setPState] = useOutletContext();
  const queryClient = useQueryClient()
  const [state, setState] = useState({});
  const [ovloader, showOvLoader] = useState(false);

  const schema = yup.object().shape({

    cfr: yup.array().of(
      yup.object().shape({
        val: yup.string().nullable()
          .required('This is required field'),
      })
    ),





  });
  var handleSave = (e) => {
    setState({ ...state, loader: true });


    instance({
      method: "post",
      url: "/update_task_cf",
      data: e,
      headers: { "Content-Type": "application/json" },
    })
      .then(function (response) {
        setState({ ...state, loader: false });
        if (response.data.status == "error") {
          toast(response.data.msg, { type: "error" });
        }
        if (response.data.status == "success") {
          toast(response.data.msg, { type: "success" });
          setPState({ ...pstate, custom_fields: response.data.custom_fields })
          setState({ ...state, editcf: false });
        }
      })
      .catch(function (response) { });
  };
  const {
    register,
    handleSubmit,
    setError,
    watch,
    getValues,
    clearErrors,
    control,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmitHandler = async (data) => {
    handleSave(data);
  };


  const BasicDetail = (props) => {
    const { label, ...other } = props;
    return (
      <>
        {other &&
          <div className="row mb-2">
            <div className="col-lg-4 text-secondary">
              {label}
            </div>
            <div className="col-lg-8" {...other}>

            </div>
          </div>
        }
      </>
    )
  }






  var BillFree = (e) => {
    setState({ ...state, loader1: true });


    instance({
      method: "GET",
      url: "/bill_free?id=" + pstate.task.id + '&action=' + e,
    })
      .then(function (response) {
        setState({ ...state, loader1: false });
        if (response.data.status == "error") {
          toast(response.data.msg, { type: "error" });
        }
        if (response.data.status == "success") {
          toast(response.data.msg, { type: "success" });
          setPState({ ...pstate, task: { ...pstate.task, bill_free: response.data.act } });
          queryClient.invalidateQueries(['tasks'])
        }
      })
      .catch(function (response) { });
  };


  const taskddModal = useModal('task-duedate');

  const showddModal = (id, dd) => {
    taskddModal.show({ task_id: id, duedate: dd }).then((res) => {
      setPState({ ...pstate, task: { ...pstate.task, due_date: res.duedate1 } });
      queryClient.invalidateQueries(['tasks'])
    });

  }
  const tasktagModal = useModal('tasktags');

  const showTagModal = (id) => {
    tasktagModal.show({ task_id: id }).then((res) => {
      setPState({ ...pstate, task: { ...pstate.task, tags: res.tags } })
    });

  }


  const taskuserModal = useModal('taskusers');

  const showuserModal = (id) => {
    taskuserModal.show({ task_id: id }).then((res) => {
      setPState({ ...pstate, refresh: Date.now() })
      queryClient.invalidateQueries(['tasks'])
    });

  }



  const handlePackageUnlink = (id, task_id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, unlink from package!'
    }).then((result) => {
      if (result.isConfirmed) {
        showOvLoader(true)
        instance
          .get("/package_task_action?package=" + id + '&action=remove_task&task=' + task_id)
          .then(function (response) {
            showOvLoader(false)
            if (response.data.status == 'success') {
              queryClient.invalidateQueries(['tasks'])
              toast(response.data.msg, { type: "success" });
              setPState({ ...pstate, refresh: Date.now() })
            } else {
              toast(response.data.msg, { type: "error" });
            }


          })

      }
    })


  }



  return (
    <div>
      <OverlayLoader visible={ovloader} />
      <div className="row">
        <div className="col-md-6">
          <div className="card card-default mb-4">
            <div className="card-body">


              <ul className='timeline mb-4'>
                {pstate.timeline.map((val, i) => (
                  <li className={`${val.done == 1 && 'active'}`} key={i}>
                    {val.status}
                    <span className="small d-block text-muted">{val.date}</span>
                  </li>
                ))}


              </ul>





              <BasicDetail label="Due Date">{pstate.task.due_date}
                {pstate.task.verified == 0 && pstate.can_edit == 1 && <UiActioButton title="Edit" className="py-0 px-1" action={() => showddModal(pstate.task.id, pstate.task.due_date)}></UiActioButton>}
              </BasicDetail>

              <BasicDetail label="Created by" >{pstate.task.created_by}</BasicDetail>
              {pstate.task.package_id > 0 &&
                <BasicDetail label="Package" ><Link to={`/view-package/${pstate.task.package_id}`}>{pstate.task.package}</Link> {pstate.task.verified == 0 && pstate.can_edit == 1 && <UiActioButton title="Close" className="py-0 px-1" action={() => handlePackageUnlink(pstate.task.package_id, pstate.task.id)} tooltip="Remove task from this package"></UiActioButton>}</BasicDetail>
              }
              {(pstate.task.bill_free == 1 && pstate.task.is_subtask != 1) &&
                <BasicDetail label="Invoice" >{pstate.task.invoice_id ? <Link to={`/view-invoice/${pstate.task.invoice_id}`}>View Invoice</Link> : <Link to={`/invoice/new?client=${pstate.task.cust_id}::${encodeURIComponent(pstate.task.name)}`}>Create Invoice</Link>}</BasicDetail>
              }

              {(pstate.can_edit == true && pstate.task.is_subtask != 1) && (<>
                {pstate.task.invoice_id == null && (
                  <div className="d-flex mt-3">
                    <span className="me-2">Is this task billable?</span>
                    <UiToggle
                      id="billfree"
                      pclass="pb-0"
                      value={pstate.task.bill_free}
                      checked={pstate.task.bill_free}
                      loading={state.loader1}
                      onChange={(e) => BillFree(e.target.value)}
                    />
                  </div>
                )}
              </>)}


              <div className="mb-4">
                <div className="d-flex border-bottom pb-2 mb-2 mt-3">
                  <div className="flex-grow-1 fs-5 fw-bold">Users</div>

                  {pstate.task.verified == 0 && pstate.can_assign == 1 && <UiActioButton title="Edit" action={() => showuserModal(pstate.task.id)}></UiActioButton>}
                </div>
                {pstate.task.users && pstate.task.users.length > 0 ? (<>

                  <UiAvatarGroup items={pstate.task.users}></UiAvatarGroup>
                </>) : <p className="text-muted">There are no Users</p>}


              </div>





              <div className="mb-4">
                <div className="d-flex border-bottom pb-2 mb-2 mt-3">
                  <div className="flex-grow-1 fs-5 fw-bold">Tags</div>
                  {pstate.can_edit == true &&
                    <UiActioButton title="Edit" action={() => showTagModal(pstate.task.id)}></UiActioButton>
                  }
                </div>
                {pstate.task.tags && pstate.task.tags.length > 0 ? (<>

                  {pstate.task.tags.map((val, index) => (
                    <UiTagDisplay title={val.tag_name} color={val.color} key={index} />

                  ))}
                </>) : <p className="text-muted">There are no Tags</p>}


              </div>



              {(pstate.custom_fields && pstate.task.is_subtask != 1) && (
                <>
                  <div className="">
                    <div className="d-flex border-bottom pb-2 mb-2 mt-3">
                      <div className="flex-grow-1 fs-5 fw-bold">Custom Fields</div>
                      {pstate.can_edit == true && <>
                        {!state.editcf && (
                          <UiActioButton title="Edit" action={() => setState({ ...state, editcf: true })}></UiActioButton>
                        )}
                      </>}
                    </div>
                    <form onSubmit={handleSubmit(onSubmitHandler)} id="taskcfsave" noValidate={"novalidate"}>
                      <input type="hidden" defaultValue={pstate.task.id} name="task" {...register(`task`)} />
                      {pstate.custom_fields.map((val, index) => (

                        <BasicDetail label={val.cf} key={index}>

                          {state.editcf == true ? (
                            <>
                              <input type="hidden" defaultValue={val.id} name={`${val.required ? 'cfr' : 'cf'}.[${index}].id`} {...register(`${val.required ? 'cfr' : 'cf'}.[${index}].id`)} />
                              {val.field_type == 'Select' ? (
                                <>
                                  <UiSelect
                                    //options={val.options}
                                    options={val.options}
                                    defaultValue={val.value}
                                    name={`${val.required ? 'cfr' : 'cf'}.[${index}].val`}
                                    message={val.required ? errors.cfr?.[index]?.val?.message : errors.cf?.[index]?.val?.message}
                                    {...register(`${val.required ? 'cfr' : 'cf'}.[${index}].val`)}
                                  />


                                </>

                              ) : (

                                <>
                                  <UiInput

                                    type="text"
                                    defaultValue={val.value}
                                    name={`${val.required ? 'cfr' : 'cf'}.[${index}].val`}
                                    message={val.required ? errors.cfr?.[index]?.val?.message : errors.cf?.[index]?.val?.message}
                                    {...register(`${val.required ? 'cfr' : 'cf'}.[${index}].val`)}
                                  />
                                </>
                              )}
                            </>
                          ) : (
                            <>{val.value}</>
                          )}
                        </BasicDetail>


                      ))}
                      {state.editcf == true && (
                        <div className="text-right">
                          <UiButton loading={state["loader"]} title="Save" />
                          <span className="btn btn-secondary ms-2" onClick={() => setState({ ...state, editcf: false })}>Cancel</span>

                        </div>
                      )}

                    </form>
                  </div>
                </>
              )}
            </div>
          </div>

        </div>
        <div className="col-md-6">
          {(pstate.task.is_checklist == 1 && pstate.task.is_subtask != 1) &&
            <div className="card card-default mb-4">
              <div className="card-header">Checklist</div>
              <div className="card-body">

                <TaskCheckList />
              </div>
            </div>
          }

          
          <div className="card card-default">
            <div className="card-header">Notes</div>
            <div className="card-body">
              <Notes />
            </div>
          </div>

        </div>
      </div>


    </div>
  )

}

export default function ViewTask() {
  let { id } = useParams();
  let navigate = useNavigate();
  const queryClient = useQueryClient()
  const [state, setState] = useState({});
  const [loader, showLoader] = useState(false);
  const [vloader, showVLoader] = useState(false);
  const [timeloader, showtimeLoader] = useState(false);
  const [bg_loader, setBGLoader] = useState(false);
  const [stopwatchLoader, setstopwatchLoader] = useState(false);
  const { timerLoading, setTimerLoading } = useStore((state) => state);

  const taskdescModal = useModal('task-description');

  const showdescModal = (id, val) => {
    taskdescModal.show({ task_id: id, description: val }).then((res) => {
      setState({ ...state, task: { ...state.task, task_description: res.description } });
      queryClient.invalidateQueries(['tasks'])
    });

  }


  const handleDelete = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        setState({ ...state, delete: true })
        instance
          .get("/delete_task?delete=" + id)
          .then(function (response) {
            setState({ ...state, delete: false })
            if (response.data.status == 'success') {
              queryClient.invalidateQueries(['tasks'])
              navigate(-1);
              toast(response.data.msg, { type: "success" });
            } else {
              toast(response.data.msg, { type: "error" });
            }


          })

      }
    })


  }

  const fetchData = () => {
    setBGLoader(true)
    instance
      .get("/task_dashboard?id=" + id)
      .then(function (response) {
        setBGLoader(false)
        setState({ ...state, ...response.data });



      })
      .catch(function (error) { })
      .then(function () { });

  }

  useEffect(() => {
    fetchData();
  }, [id]);


  useEffect(() => {
    if (state.refresh) {
      fetchData();
    }
  }, [state.refresh]);







  const UpdateStatus = (s_val) => {
    showLoader(true);

    var data = new FormData();
    data.append('task', state.task.id);
    data.append('status', s_val);

    instance({
      method: "post",
      url: "/update_task_status",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        showLoader(false);
        if (response.data.status == "error") {
          toast(response.data.msg, { type: "error" });
        }
        if (response.data.status == "success") {
          toast(response.data.msg, { type: "success" });
          fetchData();
          queryClient.invalidateQueries(['tasks'])
        }
      })
      .catch(function (response) { });
  }


  const verifyTask = (id, act) => {
    showVLoader(true);


    instance({
      method: "GET",
      url: "/verify_task?id=" + id + '&action=' + act,
    })
      .then(function (response) {
        showVLoader(false);
        if (response.data.status == "error") {
          toast(response.data.msg, { type: "error" });
        }
        if (response.data.status == "success") {
          toast(response.data.msg, { type: "success" });
          setState({ ...state, task: { ...state.task, verified: act, verified_on: response.data.verified_on } });
          queryClient.invalidateQueries(['tasks'])
        }
      })
      .catch(function (response) { });
  }


  const startTimer = (id) => {
    showtimeLoader(true);


    instance({
      method: "GET",
      url: "/update_task_timer?id=" + id,
    })
      .then(function (response) {
        showtimeLoader(false);
        if (response.data.status == "error") {
          toast(response.data.msg, { type: "error" });
        }
        if (response.data.status == "success") {
          toast(response.data.msg, { type: "success" });
          setState({ ...state, task: { ...state.task, is_running: 1, seconds: 0, timer: response.data.id } });
          setTimerLoading(true)
          queryClient.invalidateQueries(['tasks'])
        }
      })
      .catch(function (response) { });
  }

  const modalBhandle = () => {

  }

  if (state.status == 'error') {
    return (
      <PageError msg={state.msg} code={state.code} />
    )
  }

  return (
    <>
      <Helmet>
        {state.task && (
          <title>
            {state.task.service} {state.task.period ? state.task.period : ''} | Tasks | Practive
          </title>
        )}
      </Helmet>
      <div>
        {state.task ? (
          <>
            <PageHeader title="Task Dashboard" parent="Tasks" link="/tasks">

              {state.can_delete == true && (
                <UiButton className="btn btn-outline-danger ms-2" onClick={() => handleDelete(id)} title="Delete" loading={state.delete} disabled={state.can_delete == 1 && state.task.invoice_id == null ? false : true} />
              )}
            </PageHeader>





            {bg_loader == true ? <BgLoader /> : (
              <>
                <div className="card card-default mb-4">
                  <div className="card-body">
                    <div className="d-lg-flex ">
                      <div className="me-3">
                        {state.task.is_subtask == 1 && <div className="d-flex align-items-center mb-1"><Link to={'/view-task/' + state.task.parent_task_id}>{state.task.service}{state.task.period ? ' - ' + state.task.period : ''}</Link><MdOutlineArrowForwardIos className="text-secondary ms-1" /></div>}
                        <h3>{state.task.is_subtask == 1 ? state.task.title : state.task.service}</h3>

                      </div>
                      <div className="d-flex flex-wrap">
                        <div className="me-3 mb-2">
                          <UiStatusChange sts={['Pending', 'Hold', 'In-Progress', 'Completed']} status={state.task.status} onUpdate={(e) => UpdateStatus(e)} loading={loader} disabled={state.task.verified == 0 && state.can_edit == 1 ? false : true} />
                        </div>

                        <div className="me-3 mb-2">
                          {state.task.verified == 0 ?
                            <UiButton loading={vloader} className="btn btn-outline-success" title="Verify" onClick={() => verifyTask(state.task.id, 1)} disabled={state.task.status == 'Completed' && state.can_verify == 1 ? false : true}></UiButton>
                            :
                            <OverlayTrigger overlay={<Tooltip >Verified on {state.task.verified_on}</Tooltip>} >
                              <UiButton loading={vloader} className={`btn btn-success  ${state.can_verify == 1 ? '' : 'pe-none'}`} onClick={() => verifyTask(state.task.id, 0)} title={<GoCheck />}></UiButton>
                            </OverlayTrigger>
                          }
                        </div>
                        {state.task.gst_server_msg &&
                          <div className="me-3 mb-2">

                            <OverlayTrigger overlay={<Tooltip >{state.task.gst_server_msg}</Tooltip>} >
                              <div className="py-2 fs-3 lh-1 px-2 bg-light text-primary rounded-3" style={{ height: '38px' }}><BsCloudCheck /></div>
                            </OverlayTrigger>

                          </div>
                        }
                        <div className="mb-2">
                          {state.task.timer ?
                            <div className="position-relative">
                              {stopwatchLoader && <InLoader />}
                              <MyStopwatch time={state.task.seconds} id={state.task.timer} is_running={state.task.is_running} reloadPage={() => fetchData()} setmodalb={modalBhandle} showLoader={(e) => setstopwatchLoader(e)} zstateicon={(e) => setTimerLoading(e)} />
                            </div>
                            :
                            <OverlayTrigger overlay={<Tooltip >Start Timer</Tooltip>}>
                              <UiButton loading={timeloader} className="btn btn-outline-primary" title={<IoTimeOutline className="sicon" style={{ fontSize: '22px' }} />} onClick={() => startTimer(state.task.id)} disabled={state.can_edit == 1 ? false : true}></UiButton>
                            </OverlayTrigger>
                          }
                        </div>



                      </div>
                    </div>
                    {(state.task.period && state.task.is_subtask == 0) && <div className="row mt-2"><div className="col" style={{ maxWidth: '100px', minWidth: '100px' }}><span className="text-secondary" >Period:</span></div><div className="col"><b>{state.task.period}</b></div></div>}
                    <div className="row mt-2"><div className="col" style={{ maxWidth: '100px', minWidth: '100px' }}><span className="text-secondary" >Description:</span></div><div className="col">{state.task.task_description && <b>{state.task.task_description}</b>}{state.task.verified == 0 && state.can_edit == 1 && <UiActioButton className="py-0 px-1" title="Edit" action={() => showdescModal(state.task.id, state.task.task_description)}></UiActioButton>}</div></div>



                    {state.task.name && <div className="row mt-2"><div className="col" style={{ maxWidth: '100px', minWidth: '100px' }}><span className="text-secondary" >Client:</span></div><div className="col"><span className="d-flex align-items-center"><Link to={`/view-client/${state.task.cust_id}`}><b>{state.task.name}</b></Link> {state.task.mobile && <><OverlayTrigger overlay={<Tooltip >Make Call</Tooltip>}>
                      <a href={`tel:${state.task.mobile}`} target="_blank" className="ms-3"><span className="sicon"><BsTelephoneOutboundFill /></span></a>
                    </OverlayTrigger>
                      <OverlayTrigger overlay={<Tooltip >Send WhatsApp</Tooltip>}>
                        <a className="ms-2 text-success" href={`https://wa.me/${state.task.mobile}`} target="_blank"><span className="sicon"><BsWhatsapp /></span></a>
                      </OverlayTrigger></>}</span></div></div>}

                    {state.task.group_name && <p>Group: <b>{state.task.group_name}</b></p>}



                  </div>
                  <div className="tabs mx-4 border-top">
                    <NavLink to="" end className="tab-itm">Details</NavLink>
                    <NavLink to="timelog" className="tab-itm">Timelog</NavLink>
                    <NavLink to="todo" className="tab-itm">To-Do</NavLink>

                    <NavLink to="documents" className="tab-itm">Documents</NavLink>
                    <NavLink to="history" className="tab-itm">Task History</NavLink>
                  </div>
                </div>




                <Outlet context={[state, setState]} />


              </>)}

          </>
        ) : <BgLoader />}


      </div>
    </>
  );
}
