import React, { useState, useEffect } from "react";

import { useInView } from 'react-intersection-observer';

import { useNavigate, Link, useOutletContext } from "react-router-dom";
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import { Helmet } from "react-helmet";
import { instance } from "../../axios";
import { BgLoader, InLoader, PageHeader, TableZero, UiActioButton, UiDateRange, UiSelect, Avatar } from "../../ui";
import { useStore } from "../../state.js";
import { MdOutlinePending, MdOutlineAccessTime, MdOutlinePauseCircle, MdCheckCircleOutline } from 'react-icons/md'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import CountUp from 'react-countup';
import 'chartjs-adapter-date-fns'
import avgtimeImg from '../../avg_time.svg'
import taskBG from '../../task.svg'
import billBG from '../../bill.svg'
import { ToDoList } from './todo_list.js'
import { requestForToken } from '../../firebase';



import {
  Chart as ChartJS, ArcElement, CategoryScale,
  LinearScale,
  PointElement,
  TimeScale,
  TimeSeriesScale,
  LineElement,
  Title, Tooltip as Tooltip1, Legend
} from 'chart.js';
import { Doughnut, Line } from 'react-chartjs-2';
import { getStorage, setStorage } from "../../helper";




const BoxItem = (props) => {
  let navigate = useNavigate();
  const { title, subtitle, value, color, attr } = props;
  return (
    <>

      <div className="card card-default c-pointer" onClick={() => navigate(`/tasks?status=Pending,Hold,In-Progress&due_date=${attr}`)}>
        <div className="card-body py-3">
          <div className="d-flex justify-content-between">
            <span className="fs-1 fw-bolder" style={{ color: color }}><CountUp end={value} /></span>
            <div className="text-end"><b className="fs-5">{title}</b><br></br><span className="text-secondary">{subtitle}</span></div>
          </div>
        </div>
      </div>

    </>
  )
}



export default function Home() {
  ChartJS.register(CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title, ArcElement, Tooltip1, Legend);
  const [astate, setAState] = useOutletContext();
  const admin_state = useStore((state) => state.admin);
  const [state, setState] = useState({});
  const [period, setPeriod] = useState(getStorage('dashboard_period') ? getStorage('dashboard_period') : 'This Month');
  const [view, setView] = useState(astate.is_admin == 1 ? getStorage('default_role') ? getStorage('default_role') : 'Admin' : 'User');

  let navigate = useNavigate();
  const [loader, setLoader] = useState(false);

  const { ref, inView, entry } = useInView({
    triggerOnce: true,
    threshold: 1,
  });

  const fetchData = () => {
    setLoader(true)
    instance
      .get("/tasks_overview?period=" + period + "&view=" + view)
      .then(function (response) {
        setLoader(false)
        setState({ ...state, ...response.data });


      })
      .catch(function (error) { })
      .then(function () { });
  }


  const changePeriod = (e) => {
    setPeriod(e)
    setStorage('dashboard_period', e)
  }
  useEffect(() => {

    fetchData()

  }, [period, view]);
  useEffect(() => {

    requestForToken()

  }, []);








  const client_donut_opt = {

    rotation: -90,
    circumference: 180,

  }


  return (
    <>
      <Helmet>
        <title>
          Dashboard | Practive
        </title>
      </Helmet>
      <div>

        <PageHeader noBack={true} title="Dashboard" stacked={true}>
          <div className="d-flex flex-sm-row flex-column align-items-sm-center" style={{ maxWidth: '450px' }}>
            {(admin_state.is_admin && admin_state.is_admin == 1) ?
              <div className="d-flex align-items-center mb-2 mb-sm-0">
                <span className="me-2  d-lg-block">View as</span>
                <UiSelect parentClass="mb-0 me-3" value={view} onChange={(e) => {setView(e.target.value); setStorage('default_role', e.target.value)}} noBlank={true} name="view" options={['Admin', 'User']} />
              </div> : ''
            }
            <div className="flex-grow-1" style={{ minWidth: '230px' }}>

              <UiDateRange
                parentClass="mb-0"
                onUpdate={(e) => changePeriod(e)}
                value={period}
                //ref={ref}
                name='date'

              />
            </div>
          </div>
        </PageHeader>
        <div className="s-box-scroll mb-4">
          <div className="row" style={{ height: '80px' }}>
            {state.stat1 && state.stat1.map((val, i) => (
              <div key={i} className="col"  >
                <BoxItem title={val.title} subtitle={val.subtitle} value={val.value} color={val.color} attr={val.attr} />
              </div>
            ))}



          </div>
        </div>
        <div className="row">
          <div className="col-md-4  mb-4">
            <ToDoList view={view} />

          </div>



          <div className="col-md-5 mb-4">
            <TasksOverview items={state.services} loader={loader} period={period} />
          </div>
          <div className="col-md-3  mb-4">
            <div className="mb-4" style={{ height: '173px' }}>
              <div className="card text-white c-pointer" onClick={() => navigate(`/tasks?status=Pending,Hold,In-Progress&date=${period}`)} style={{ backgroundColor: '#F1416C' }}>
                <div className="card-body">
                  <div className="d-flex justify-content-between">
                    <span className="fs-1 fw-bolder"><CountUp end={state.uncompleted} /></span>
                    <img style={{ width: '38px', opacity: '0.2' }} src={taskBG} />
                  </div>

                  <p className="text-white text-opacity-75 text-truncate">Need to complete tasks</p>
                  <p className="text-sm text-white text-opacity-75 mb-1">{state.completed} Completed</p>
                  <div className="progress bg-white bg-opacity-50" style={{ height: '10px' }} role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                    <div className="progress-bar bg-white" style={{ width: (state.completed ? state.completed : '0%') }}></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card card-default" style={{ height: '372px' }}>
              {loader == true && <InLoader />}
              <div className="card-body p-4">
                <h2 className="fs-5 fw-semibold mb-3">Task Summary</h2>
                {state.status_data && state.services.length > 0 &&
                  <div className="d-flex justify-content-center" style={{ height: '290px' }}>

                    <Doughnut data={state.status_data} />

                  </div>
                }
                {state.status_data && state.services.length == 0 && <TableZero title="Data" />}
              </div>
            </div>
          </div>
        </div>


        <div className="row">
          <div className="col-md-3 mb-4">
            {view == 'Admin' && <>
              <div className="mb-4" style={{ height: '173px' }}>
                <div className="card text-bg-primary" >
                  <div className="card-body">
                    <div className="d-flex justify-content-between mb-2">
                      <p className="text-white text-opacity-75 text-truncate">Need to make invoice</p>

                      <img style={{ width: '38px', opacity: '0.2' }} src={billBG} />
                    </div>
                    <div className="d-flex justify-content-between">
                      <div className="col border-end border-white border-opacity-25">
                      <Link to="/unbilled-packages-report" className="text-white">
                        <span className="fs-2 fw-bolder"><CountUp end={state.packages} /></span>
                        <p className="text-white text-opacity-75 text-truncate mb-0">Packages</p>
                      </Link>
                      </div>
                      <div className="text-end col" >
                        <Link to="/unbilled-tasks-report" className="text-white">
                          <span className="fs-2 fw-bolder"><CountUp end={state.unbilled} /></span>
                          <p className="text-white text-opacity-75 text-truncate mb-0">Tasks</p>
                        </Link>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <div className="card card-default" style={{ height: '353px' }}>
                {loader == true && <InLoader />}
                <div className="card-body p-4">
                  <h2 className="fs-5 mb-3 fw-semibold">New Clients {state.new_clients > 0 && <span className="badge bg-primary">{state.new_clients}</span>}</h2>
                  {state.client_data && state.client_data.labels.length > 0 &&
                    <div className="d-flex justify-content-center" style={{ height: '263px' }}>
                      <Doughnut options={client_donut_opt} data={state.client_data} />
                    </div>
                  }
                  {state.client_data && state.client_data.labels.length == 0 && <TableZero title="Data" />}
                </div>
              </div>
            </>}
            {view == 'User' && <>
              <AvgHours period={period} view={view} height="260px" />

              <UpcomingHolidays height="267px" sheight={178} />

            </>}
          </div>
          <div className="col-md-9 mb-4">
            <TaskLineChart period={period} view={view} />
          </div>
        </div>

        {view == 'Admin' &&
          <div className="row" ref={ref}>
            <div className="col-md-6 mb-4" >
              {inView ? <BestPerformer period={period} view={view} /> : ''}
            </div>
            <div className="col-md-6 mb-4">
              {inView ? <Attendance period={period} view={view} /> : ''}

            </div>
          </div>
        }
        {view == 'Admin' &&
          <div className="row">
            <div className="col-md-4">
              {inView ? <AvgHours period={period} view={view} height="395px" /> : ''}
            </div>

            <div className="col-md-4">
              <div className="card mb-4" style={{ height: '395px' }}>
                <div className="card-header">Useful Links</div>
                <div className="card-body pt-3">
                  <Link className="d-block list1" to="/website-settings">
                    Website Settings
                  </Link>
                  <Link className="d-block list1" to="/account">
                    Renew/Upgrade Plan
                  </Link>
                  <Link className="d-block list1" to="/notification-settings">
                    Buy SMS
                  </Link>
                  <Link className="d-block list1" to="/notification-settings">
                    WhatsApp API
                  </Link>
                  <Link className="d-block list1" to="/website-promo">
                    Download Website Flyer
                  </Link>
                  <a href="https://help.practive.in" className="d-block list1" target="_blank">
                    Practive Support
                  </a>




                </div>

              </div>
            </div>

            <div className="col-md-4">
              {inView ? <UpcomingHolidays height="395px" /> : ''}
            </div>

          </div>
        }
      </div>
    </>
  );
}


export function TasksOverview(props) {
  const { loader, items, period } = props;
  let navigate = useNavigate();






  return (
    <div className="card card-default">
      <div className="card-header"><div className="d-flex justify-content-between">
        <span>Tasks</span>
        <UiActioButton title="Add" tooltip="New Task" action={() => navigate('/task/new')}></UiActioButton>
      </div></div>
      <SimpleBar style={{ height: 496 }}>
        <div className="position-relative" >
          {loader == true && <InLoader />}
          <div className="card-body pt-3">
            {items && items.length > 0 ?
              <>
                <div className="row mb-2">
                  <div className="col-4 text-muted fw-semibold text-uppercase">
                    Service
                  </div>
                  <div className="col-2">
                    <OverlayTrigger overlay={<Tooltip >Pending</Tooltip>} >
                      <span className="fs-4 text-warning justify-content-center sicon"> <MdOutlinePending /></span>
                    </OverlayTrigger>

                  </div>
                  <div className="col-2">
                    <OverlayTrigger overlay={<Tooltip >Hold</Tooltip>} >
                      <span className="fs-4 text-muted justify-content-center sicon"> <MdOutlinePauseCircle /></span>
                    </OverlayTrigger>
                  </div>
                  <div className="col-2">
                    <OverlayTrigger overlay={<Tooltip >In-Progress</Tooltip>} >
                      <span className="fs-4 text-primary justify-content-center sicon"> <MdOutlineAccessTime /></span>
                    </OverlayTrigger>
                  </div>
                  <div className="col-2">
                    <OverlayTrigger overlay={<Tooltip >Completed</Tooltip>} >
                      <span className="fs-4 text-success justify-content-center sicon"><MdCheckCircleOutline /></span>
                    </OverlayTrigger>
                  </div>
                </div>

                {items.map((val, i) => (
                  <div className="row border-top py-2" key={i}>
                    <div className="col-4">
                      <p className="text-truncate text-start mb-0 " title={val.service}>{val.service}</p>
                    </div>
                    <div className="col-2 text-center"><Link to={`/tasks?service=${val.id}&status=Pending&date=${period}`}>{val.pending}</Link></div>
                    <div className="col-2 text-center"><Link to={`/tasks?service=${val.id}&status=Hold&date=${period}`}>{val.hold}</Link></div>
                    <div className="col-2 text-center"><Link to={`/tasks?service=${val.id}&status=In-Progress&date=${period}`}>{val.in_progress}</Link></div>
                    <div className="col-2 text-center"><Link to={`/tasks?service=${val.id}&status=Completed&date=${period}`}>{val.completed}</Link></div>
                  </div>

                ))}
              </>
              : items && items.length == 0 && <TableZero title="Tasks" />}

          </div>
        </div>
      </SimpleBar>
    </div>
  )
}




export function BestPerformer(props) {
  const { period } = props
  let navigate = useNavigate();

  const [state, setState] = useState({});
  const [loader, setLoader] = useState(false);


  const fetchData = () => {
    setLoader(true)
    instance
      .get("/best_performer?period=" + period)
      .then(function (response) {
        setLoader(false)
        setState({ ...state, ...response.data });


      })
      .catch(function (error) { })
      .then(function () { });
  }


  useEffect(() => {

    fetchData()

  }, [period]);




  return (
    <div className="card card-default">
      <div className="card-header">
        <div className="d-flex justify-content-between">
          <span>Best Performer</span>
          <UiActioButton title="Go" className="fs-4" tooltip="Employees Report" action={() => navigate("/employees-report")}></UiActioButton>
        </div>
      </div>
      <SimpleBar style={{ height: 450 }}>
        <div className="position-relative">
          {loader == true && <InLoader />}
          <div className="card-body">
            {state.items && state.items.length > 0 ?
              <>
                <div className="d-flex mb-2 text-uppercase fw-semibold text-muted">
                  <div className="col-6">
                    User
                  </div>
                  <div className="col text-center text-truncate">
                    Total

                  </div>
                  <div className="col text-center text-truncate">
                    Completed
                  </div>
                  <div className="col  text-center text-truncate">
                    %
                  </div>

                </div>

                {state.items.map((val, i) => (
                  <div className="d-flex border-top align-items-center py-2" key={i}>
                    <div className="col-6">
                      <span className="d-inline-flex align-items-center  mt-1">
                        <OverlayTrigger overlay={<Tooltip >{val.name}</Tooltip>} >
                          <span className="me-2 d-flex">
                            <Avatar name={val.name} className="bg-secondary d-inline-flex " src={val.photo} size="30" textSizeRatio={3} round={true} />
                          </span>
                        </OverlayTrigger>
                        <span>{val.name}<br></br> <small className="text-secondary">{val.role}</small></span>

                      </span>
                    </div>
                    <div className="col text-center"><Link to={`/tasks?users=${val.id}&date=${period}`}>{val.total}</Link></div>
                    <div className="col text-center"><Link to={`/tasks?users=${val.id}&status=Completed&date=${period}`}>{val.completed}</Link></div>
                    <div className="col text-center text-secondary">{Math.round(val.completed / val.total * 100)}%</div>
                  </div>

                ))}
              </>
              : state.items && state.items.length == 0 && <TableZero title="Tasks" />}

          </div>
        </div>
      </SimpleBar>
    </div>
  )
}



export function Attendance(props) {
  const { period } = props
  let navigate = useNavigate();

  const [state, setState] = useState({});
  const [loader, setLoader] = useState(false);


  const fetchData = () => {
    setLoader(true)
    instance
      .get("/attendance_widget?period=" + period)
      .then(function (response) {
        setLoader(false)
        setState({ ...state, ...response.data });


      })
      .catch(function (error) { })
      .then(function () { });
  }


  useEffect(() => {

    fetchData()

  }, [period]);




  return (
    <div className="card card-default">
      <div className="card-header">
        <div className="d-flex justify-content-between">
          <span>Attendance</span>
          <UiActioButton title="Go" className="fs-4" tooltip="Attendance Report" action={() => navigate("/attendance-report")}></UiActioButton>
        </div>
      </div>
      <SimpleBar style={{ height: 450 }}>
        <div className="position-relative ">
          {loader == true && <InLoader />}
          <div className="card-body">
            {state.items && state.items.length > 0 ?
              <>
                <div className="d-flex mb-2 text-uppercase fw-semibold text-muted">
                  <div className="col-6">
                    User
                  </div>
                  <div className="col text-center text-truncate">
                    Present

                  </div>
                  <div className="col text-center text-truncate">
                    Absent
                  </div>
                  <div className="col text-center text-truncate">
                    %
                  </div>

                </div>

                {state.items.map((val, i) => (
                  <div className="d-flex border-top align-items-center py-2" key={i}>
                    <div className="col-6">
                      <span className="d-inline-flex align-items-center  mt-1">
                        <OverlayTrigger overlay={<Tooltip >{val.name}</Tooltip>} >
                          <span className="me-2 d-flex">
                            <Avatar name={val.name} className="bg-secondary d-inline-flex " src={val.photo} size="30" textSizeRatio={3} round={true} />
                          </span>
                        </OverlayTrigger>
                        <span>{val.name}<br></br> <small className="text-secondary">{val.role}</small></span>

                      </span>
                    </div>
                    <div className="col text-secondary text-center">{val.present}</div>
                    <div className="col text-secondary text-center">{val.absent}</div>
                    <div className="col text-secondary text-center">{Math.round(parseInt(val.present) / (parseInt(val.present) + parseInt(val.absent)) * 100)}%</div>
                  </div>

                ))}
              </>
              : state.items && state.items.length == 0 && <TableZero title="Tasks" />}

          </div>
        </div>
      </SimpleBar>
    </div>
  )
}





export function TaskLineChart(props) {
  const { period, view } = props
  let navigate = useNavigate();

  ChartJS.register(CategoryScale,
    LinearScale,
    TimeScale,
    TimeSeriesScale,
    PointElement,
    LineElement,
    Title, ArcElement, Tooltip1, Legend);

  const [state, setState] = useState({});
  const [loader, setLoader] = useState(false);
  const options = {
    responsive: true,
    scales: {
      x: {
        type: 'time',
        grid: {
          display: false,
        },
        time: {
          unit: 'day',
        },
      },

    },
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
        display: false
      },
      title: {
        display: false,

      },
    },
  };
  const [optns, setOptns] = useState(options);

  const fetchData = () => {
    setLoader(true)
    instance
      .get("/tasks_performance?period=" + period + "&view=" + view)
      .then(function (response) {
        setLoader(false)
        setState({ ...state, ...response.data });

        if (response.data.type) {

          var arr = options;
          arr.scales.x.time.unit = response.data.type;
          setOptns(arr)
        }

      })
      .catch(function (error) { })
      .then(function () { });
  }


  useEffect(() => {

    fetchData()

  }, [period, view]);





  return (
    <div className="card card-default" style={{ height: '550px' }}>
      <div className="card-header"><div className="d-flex justify-content-between">
        <span>Performance</span>
      </div></div>

      {loader == true && <InLoader />}
      <div className="card-body">
        {state.items &&
          <Line options={optns} data={state.items} />}


      </div>
    </div>
  )

}



export function AvgHours(props) {

  const { period, view, height } = props


  const [state, setState] = useState({});
  const [loader, setLoader] = useState(false);
  function toHoursAndMinutes(totalMinutes) {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    return { hours, minutes };
  }

  const fetchData = () => {
    setLoader(true)
    instance
      .get("/avg_task_time?period=" + period + "&view=" + view)
      .then(function (response) {
        setLoader(false)
        setState({ ...state, ...response.data });


      })
      .catch(function (error) { })
      .then(function () { });
  }


  useEffect(() => {

    fetchData()

  }, [period, view]);



  return (
    <div className="card card-default text-bg-warning mb-4" style={{ height: height }}>


      {loader == true && <InLoader />}
      <div className="card-body" style={{ backgroundImage: `url(${avgtimeImg})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'bottom,center', backgroundSize: '80%' }}>

        {state.avg_hours && state.avg_hours > 0 ?
          <>
            <h3 className="fw-bold fs-1">{toHoursAndMinutes(state.avg_hours).hours}:{toHoursAndMinutes(state.avg_hours).minutes} Hours </h3>
            <p className="text-dark text-opacity-75">to complete task</p>
          </> : <h3 className="fw-light">No Data</h3>
        }

      </div>
    </div>
  )
}




export function UpcomingHolidays(props) {

  const { period, view, height, sheight } = props


  const [state, setState] = useState({});
  const [loader, setLoader] = useState(false);


  const fetchData = () => {
    setLoader(true)
    instance
      .get("/upcoming_holidays")
      .then(function (response) {
        setLoader(false)
        setState({ ...state, ...response.data });


      })
      .catch(function (error) { })
      .then(function () { });
  }


  useEffect(() => {

    fetchData()

  }, []);



  return (
    <div className="card card-default" style={{ height: height }}>
      <div className="card-header">
        Upcoming Holidays
      </div>

      {loader == true && <InLoader />}
      <div className="card-body pt-3">
        <SimpleBar style={{ height: sheight }}>
          {state.items && state.items.length == 0 && <TableZero title="Data" />}
          {state.items && state.items.map((val, i) => (



            <div className="d-flex list1 justify-content-between" key={i}>
              <span className="text-truncate">{val.title}</span>
              <span className="text-secondary" style={{ minWidth: '80px' }}>{val.date}</span>

            </div>

          ))
          }
        </SimpleBar>

      </div>
    </div>
  )
}




