import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

import { instance } from "../../axios";
import { BgLoader, InLoader, PageHeader, TableZero, UiActioButton, UiInput } from "../../ui";
import { FixedSizeList as List } from 'react-window';
import InfiniteLoader from "react-window-infinite-loader";
import { TodoItem } from "../../todo-item";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';

export function ToDoList(props) {
const { view } = props
  const [todos, setTodos] = useState([]);
  const [isNextPageLoading, setisNextPageLoading] = useState(false);
  const [hasNextPage, sethasNextPage] = useState(false);
  const [todoLoader, setTodoLoader] = useState(false);
  const [todo_category, setTodoCategory] = useState('Today');
  const [page, setPage] = useState(1);
  let navigate = useNavigate();


  const [state, setState] = useState({});
  const [loader, setLoader] = useState(false);
  const fetchTodos = () => {
    setTodoLoader(true)
    instance
      .get("/todo-dash?category=" + todo_category + "&view=" + view)
      .then(function (response) {
        setTodoLoader(false)
        setState({ ...state, ...response.data })
        setTodos([...response.data.items])
        // setPage(parseInt(response.data.page) + 1)
        setPage(response.data.items.length)

        if (response.data.hasMoreItems == true) {
          sethasNextPage(true)
        } else {
          sethasNextPage(false)
        }

      })
      .catch(function (error) { })
      .then(function () { });
  }

  const fetchTodo = (id) => {
    setTodoLoader(true)
    instance
      .get("/todo-dash?category=" + todo_category + "&id=" + id + "&view=" + view)
      .then(function (response) {
        setTodoLoader(false)
        var arr = todos.slice();
        var index = arr.map(function (el) {
          return el.id;
        }).indexOf(id);

        if (response.data.items.length > 0) {
          arr[index] = response.data.items[0];
          setTodos(arr)

        } else {
          arr.splice(index, 1);
          setTodos(arr)

        }




      })
      .catch(function (error) { })
      .then(function () { });
  }





  const loadNextPage = () => {
    instance
      .get("/todo-dash?category=" + todo_category + "&page=" + todos.length + "&view=" + view)
      .then(function (response) {


        setTodos([...todos, ...response.data.items])
        //setPage(parseInt(response.data.page) + 1)
        setPage(todos.length)
        setisNextPageLoading(false)
        if (response.data.hasMoreItems == true) {
          sethasNextPage(true)
        } else {
          sethasNextPage(false)
        }

      })
      .catch(function (error) { })
      .then(function () { });
  }
  const itemCount = hasNextPage ? todos.length + 1 : todos.length;


  const loadMoreItems = isNextPageLoading ? () => { } : loadNextPage;

  const isItemLoaded = index => !hasNextPage || index < todos.length;

  const Item = ({ index, style }) => {
    let content;
    if (!isItemLoaded(index)) {
      content = null;
    } else {
      content = todos[index];
    }

    return <div style={style} className="px-3">{content ? <TodoItem val={content} key='1' fullHeight={true} onDelete={handleDelete} hideDetails={true} onComplete={completeTodo} onView={ViewToDo} onEdit={showAddModal} /> : <BgLoader />}</div>;
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        setTodoLoader(true)
        instance
          .get("/update_todo?delete=" + id)
          .then(function (response) {
            setTodoLoader(false)
            if (response.data.status == 'success') {
              var arr = todos.slice();
              var arr1 = arr.filter(x => x.id !== id)
              setTodos(arr1)
              toast(response.data.msg, { type: "success" });
            } else {
              toast(response.data.msg, { type: "error" });
            }

          })

      }
    })


  }




  const completeTodo = (todo, act) => {
    setTodoLoader(true)
    instance
      .get("/complete_todo?action=" + act + "&todo=" + todo)
      .then(function (response) {
        setTodoLoader(false)
        if (response.data.status == 'success') {

          if(response.data.repeat){
            fetchTodos()
          } else {
            fetchTodo(todo)
          }
          
        } else if (response.data.status == 'error') {
          toast(response.data.msg, { type: "error" });
        }



      })
      .catch(function (error) { })
      .then(function () { });

  }

  const userModal = useModal('todo');


  const showAddModal = (row) => {

    userModal.show({ data: row }).then((res) => {

      if (!row) {
        fetchTodos()
      } else {
        fetchTodo(row.id)
      }
    });
  }

  const viewtodoModal = useModal('view-todo');


  const ViewToDo = (row) => {
    viewtodoModal.show({ data: row }).then((res) => {
      if (res.resolved == true) {
        fetchTodo(row.id)
      }

    });
  }



  useEffect(() => {

    fetchTodos()

  }, [todo_category, view]);

  








  return (
    <div className="card card-deafault">
      <div className="card-header">
        <div className="d-flex justify-content-between">
          <span>To-Do</span>
          <UiActioButton title="Add" tooltip="New To-Do" action={() => showAddModal()}></UiActioButton>
        </div>
      </div>

      <div className="tabs text-center d-flex">
        <span className={`tab-itm c-pointer flex-grow-1 ${todo_category == 'Today' && 'active'}`} onClick={() => setTodoCategory('Today')}>Today</span>
        <span className={`tab-itm c-pointer flex-grow-1 ${todo_category == 'Upcoming' && 'active'}`} onClick={() => setTodoCategory('Upcoming')}>Upcoming</span>
        <span className={`tab-itm c-pointer flex-grow-1 ${todo_category == 'Completed' && 'active'}`} onClick={() => setTodoCategory('Completed')}>Completed</span>

      </div>
      <div style={{ height: '450px' }}>

        <div className="position-relative h-100" >
          {todoLoader == true && <InLoader />}
          <div className="h-100">

            {state.items && todos.length > 0 ?
              <InfiniteLoader
                isItemLoaded={isItemLoaded}
                itemCount={itemCount}
                threshold={1}
                loadMoreItems={loadMoreItems}
              >
                {({ onItemsRendered, ref }) => (
                  <SimpleBar style={{ height: 450 }}>
                    {({ scrollableNodeRef, contentNodeRef }) => (
                      <List
                        className="List"
                        height={450}
                        itemCount={itemCount}
                        itemSize={64}
                        onItemsRendered={onItemsRendered}
                        ref={ref}
                        outerRef={scrollableNodeRef}
                        innerRef={contentNodeRef}
                        width={'100%'}
                      >
                        {Item}
                      </List>
                    )}
                  </SimpleBar>
                )}
              </InfiniteLoader>
              : state.items && todos.length == 0 && <div className="d-flex align-items-center justify-content-center mt-3"><TableZero title="To-Do"></TableZero></div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}
