import React, { useState, useEffect, useMemo } from "react";
import { instance } from "../../axios";
import { UiSelect, UiToggle, UiDatePicker, UiInput, UiButton, UiRSelect, BgLoader, OverlayLoader, TableZero, PageHeader, UiActioButton, InLoader, PageError, UiTextArea, UiPopover } from "../../ui";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup"; import { NavLink, useNavigate, useParams, useLocation, Outlet, useOutletContext } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import DataTable from 'react-data-table-component';
import { format, addMonths, isBefore, parse } from 'date-fns';

import { Helmet } from "react-helmet";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from 'react-sortable-hoc';
import { arrayMoveImmutable as arrayMove } from 'array-move';
import { MdOutlineDragHandle } from "react-icons/md";
import { GoPlus } from "react-icons/go";
import { useQueryClient } from 'react-query';


//const days = ('' + Array(31)).split(',').map(function () { return this[0]++; }, [1]);
const days = ['1st', '2nd', '3rd', '4th', '5th', '6th', '7th', '8th', '9th', '10th', '11th', '12th', '13th', '14th', '15th', '16th', '17th', '18th', '19th', '20th', '21st', '22nd', '23rd', '24th', '25th', '26th', '27th', '28th', 'Last Day'];

export function ServiceCF() {
  const [state, setState] = useState({});
  const [bg_loader, setBGLoader] = useState(false);
  const [pstate, setPSate] = useOutletContext();
  const userModal = useModal('servicecustomfield');
  const handleDelete = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        setBGLoader(true)
        instance
          .get("/update_service_custom_field?delete=" + id)
          .then(function (response) {
            setBGLoader(false)
            if (response.data.status == 'success') {
              toast(response.data.msg, { type: "success" });
            } else {
              toast(response.data.msg, { type: "error" });
            }

            fetchCF();

          })

      }
    })


  }
  const columns = [
    {
      name: 'Custom Field',
      selector: row => row.field_name,
      sortable: true,
      cell: row => (<b className="text-dark">{row.field_name}</b>)
    },
    {
      name: 'Field Type',
      sortable: true,
      selector: row => row.field_type,
    },

    {
      name: 'Options',
      selector: row => row.options,
    },
    {
      name: '',
      width: (window.innerWidth > 768 ? '0px' : '130px'),
      selector: row => row.status,
      cell: row => (
        <div className="tableaction">
          <UiActioButton title="Edit" action={() => showAddModal(row, pstate.service.id)} />
          <UiActioButton title="Delete" action={() => handleDelete(row.id)} />

        </div>

      )
    },
  ];

  const fetchCF = () => {
    setBGLoader(true)
    instance
      .get("/service_custom_fields?id=" + pstate.service.id)
      .then(function (response) {
        setBGLoader(false)
        setState({ ...state, ...response.data });

      })
      .catch(function (error) { })
      .then(function () { });

  }

  useEffect(() => {
    fetchCF();
  }, []);
  const showAddModal = (row, id) => {

    userModal.show({ data: row, idd: id }).then((res) => {
      fetchCF();
    });

  }


  return (
    <div className="card card-default position-relative">

      {state.custom_fields ? (
        <>
          {bg_loader == true && <InLoader />}
          <div className="card-body">

            <UiButton className="btn btn-primary" onClick={() => showAddModal(null, pstate.service.id)} title="New" icon="add"></UiButton>


          </div>
          <div className="card-body pt-0 tablecard">

            <DataTable
              columns={columns}
              data={state.custom_fields}
              noDataComponent={<TableZero title="Custom Fields" />}
            />

          </div>
        </>
      ) : <BgLoader />}


    </div>
  )

}


const DragHandle = sortableHandle(() => <span className="h2 pe-3 mb-0 text-secondary"><MdOutlineDragHandle /></span>);

const SortableItem = sortableElement((value) => {
  return (
    <div className="d-flex align-items-center bg-light p-3 rounded mb-2">
      <DragHandle />
      <div className="w-100 d-flex" {...value} />
    </div>
  )
});
const SortableContainer = sortableContainer(({ children }) => {
  return <div>{children}</div>;
});

export function ServiceCheckList() {
  const [pstate, setPSate] = useOutletContext();
  const [bg_loader, setBGLoader] = useState(false);
  const [state, setState] = useState({ count: 0 });
  const [entry, setEntry] = useState([]);
  const schema = yup.object().shape({

    step: yup.array().of(
      yup.object().shape({
        step: yup.string().nullable().required("This is required field").max(250, 'Maximum character limit is 250'),
      })
    ),



  });

  const insertEntry = () => {
    // setState({ ...state, count: state.count + 1 })
    //setEntry([...entry, { id: state.count }])
    append()

  }
  const {
    register,
    getValues,
    unregister,
    resetField,

    handleSubmit,
    control,
    watch,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });


  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "step", // unique name for your Field Array
  });


  const deleteEntry = (id) => {
    if (fields) {
      if (fields.length == 1) {
        handleDelete(pstate.service.id)
      }
    }

    remove(id)
  }
  const onSubmitHandler = async (data) => {
    handleSave();
  };


  const onSortEnd = ({ oldIndex, newIndex }) => {
    move(oldIndex, newIndex)
    // var newsort = arrayMove(entry, oldIndex, newIndex);
    //setEntry(newsort)
  };

  const handleDelete = (id) => {

    setBGLoader(true)
    instance
      .get("/delete_checklist?id=" + id)
      .then(function (response) {
        setBGLoader(false)
        if (response.data.status == 'success') {
          toast(response.data.msg, { type: "success" });
          setState({ ...state, checklist: [] });
        } else {
          toast(response.data.msg, { type: "error" });
        }



      })




  }
  var handleSave = (e) => {
    setState({ ...state, loader: true });
    var form = document.querySelector("#servicechecklistfrm");
    var data = new FormData(form);

    instance({
      method: "post",
      url: "/service_update_checklist",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        setState({ ...state, loader: false });
        if (response.data.status == "error") {
          toast(response.data.msg, { type: "error" });
        }
        if (response.data.status == "success") {
          toast(response.data.msg, { type: "success" });
          setState({ ...state, checklist: response.data.checklist, editing: false })
          //setEntry(response.data.checklist)
        }
      })
      .catch(function (response) { });
  };


  const fetchData = () => {
    //setEntry([{id:0,step:'ok'}]);
    instance
      .get("/service_checklist?id=" + pstate.service.id)
      .then(function (response) {
        setState({ ...state, ...response.data });
        //setEntry(response.data.checklist);
        reset(response.data)


      })
      .catch(function (error) { })
      .then(function () { });

  }

  const columns = [
    {
      name: 'Step No.',
      selector: row => row.id,
      maxWidth: '100px',
      cell: row => (<b>{row.id + 1}</b>)
    },
    {
      name: 'Step',
      selector: row => row.step,
    },
  ];
  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    //reset()
    //if (state.checklist) {

    //setEntry(state.checklist);
    //setState({ ...state, count: state.checklist.length });
    //} else {
    //      setEntry([]);
    //    setState({ ...state, count: 0 });
    //}
  }, [state.checklist]);


  return (
    <div className="card card-default">
      <div className="card-body">
        {bg_loader == true ? <BgLoader /> : (
          <>
            {state.step ? (

              <div>
                <form id="servicechecklistfrm" onSubmit={handleSubmit(onSubmitHandler)} noValidate>

                  <div className="d-flex align-items-center mb-4">

                    {fields && fields.length > 0 && (
                      <>
                        <h2 className="flex-grow-1 m-0 fs-4">Update Checklist</h2>
                        <UiButton loading={state["loader"]} title="Save" />
                      </>
                    )}
                  </div>
                  <SortableContainer onSortEnd={onSortEnd} useDragHandle>
                    {fields && fields.length > 0 && fields.map((value, i) => (
                      <SortableItem key={i} index={i}  >
                        <div key={i} className="flex-grow-1"><UiInput
                          placeholder="Checklist Step"
                          type="text"
                          className="mb-0"
                          name={`step[${i}].step`}
                          parentClass="mb-0"
                          message={errors.step?.[i]?.step?.message}
                          noerror="1"
                          defaultValue={value.step}
                          {...register(`step.[${i}].step`)}
                        />

                        </div>
                        <UiButton className="btn btn-outline-danger ms-3" onClick={() => deleteEntry(i)} title="" icon="delete"></UiButton>
                      </SortableItem>
                    ))}


                  </SortableContainer>

                  {pstate.service.id && (<input type="hidden" value={pstate.service.id} name="service" />)}

                </form>
                <div className="text-center">
                  <UiButton className="btn btn-outline-success" onClick={() => insertEntry()} title="Add Step" icon="add"> </UiButton>
                </div>
              </div>
            ) : <BgLoader />}
          </>
        )}
      </div>
    </div>
  )

}
export function ServiceSettings() {
  const [pstate, setPState] = useOutletContext();
  const navigate = useNavigate()
  const [state, setState] = useState({});
  const schema = yup.object().shape({
    recurring: yup.bool(),
    gst_api: yup.bool(),
    name: yup.string().nullable().required("Enter service name").max(80, 'Maximum character limit is 80'),
    gst: yup.string().nullable().required("Select GST tax slab"),
    sac: yup.string().nullable().when('sac', {
      is: (value) => value?.length,
      then: (rule) => rule.matches(/^[0-9]+$/, "Enter 6 digit SAC code")
        .min(6, "Enter 6 digit SAC code")
        .max(6, "Enter 6 digit SAC code"),
    }),
    price: yup.string().nullable().when('price', {
      is: (value) => value?.length,
      then: (rule) => rule.matches(/^[0-9]+$/, "Enter default billing price")

    }),
    frequency: yup.string().nullable().when('recurring', {
      is: true,
      then: yup.string().nullable().required('Select auto creation frequency')
    }),
    monthly_day: yup.string().nullable().when('frequency', {
      is: 'Monthly',
      then: yup.string().nullable().required('Select day')
    }),
    monthly_due_day: yup.string().nullable().when('frequency', {
      is: 'Monthly',
      then: yup.string().nullable().required('Select day')
    }),

    quarterly_day: yup.string().nullable().when('frequency', {
      is: 'Quarterly',
      then: yup.string().nullable().required('Select day')
    }),
    quarterly_month: yup.string().nullable().when('frequency', {
      is: 'Quarterly',
      then: yup.string().nullable().required('Select month')
    }),

    quarterly_due_day: yup.string().nullable().when('frequency', {
      is: 'Quarterly',
      then: yup.string().nullable().required('Select day')
    }),
    quarterly_due_month: yup.string().nullable().when('frequency', {
      is: 'Quarterly',
      then: yup.string().nullable().required('Select month')
    }),

    yearly_daymonth: yup.string().nullable().when('frequency', {
      is: 'Yearly',
      then: yup.string().nullable().required('Select day and month')
    }),

    yearly_due_daymonth: yup.string().nullable().when('frequency', {
      is: 'Yearly',
      then: yup.string().nullable().required('Select day and month')
    }),

    users: yup.array().of(
      yup.object().shape({
        label: yup.string(),
        value: yup.string(),
      })),

    status: yup.bool(),
    doc_req: yup.bool(),

    req_msg: yup.string().nullable().when('doc_req', {
      is: (value) => value == 1,
      then: (rule) => rule.required("Document request message is required")
    }),

    gst_return: yup.object().nullable().when(['gst_api', 'recurring'], {
      is: (val1, val2) => {
        if (val2 == true && val1 == 1) {
          return true
        } else return false
      },
      then: (rule) => rule.required("GST return type is required")
    }),
  }, [
    ['sac', 'sac'], ['price', 'price']
  ]);
  const {
    register,
    handleSubmit,
    control,
    watch,
    getValues,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const watchType = watch("recurring");
  const watchFreq = watch("frequency");
  const w_monthly_day = watch("monthly_day");
  const w_quarterly_day = watch("quarterly_day");
  const w_quarterly_month = watch("quarterly_month");
  const w_yearly_daymonth = watch("yearly_daymonth");
  const w_use_client_users = watch("use_client_users");
  const w_doc_req = watch("doc_req");
  const w_gst_api = watch("gst_api");

  const onSubmitHandler = async (data) => {
    handleSave();
  };
  var handleSave = (e) => {
    setState({ ...state, loader: true });
    var form = document.querySelector("#servicesettingsfrm");
    var data = new FormData(form);

    instance({
      method: "post",
      url: "/update_service",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        setState({ ...state, loader: false });
        if (response.data.status == "error") {
          toast(response.data.msg, { type: "error" });
        }
        if (response.data.status == "success") {
          queryClient.invalidateQueries(['services'])

          toast(response.data.msg, { type: "success" });
          //instance
          //.get("/service?id=" + pstate.service.id)
          //.then(function (response) {
          //setPState(response.data);
          //})

          //navigate("/services")
        }
      })
      .catch(function (response) { });
  };
  const queryClient = useQueryClient()

  useEffect(() => {
    reset(pstate.service);
  }, []);
  useEffect(() => {
    if (watchType == 0) {
      reset({ ...getValues(), frequency: '' });
    }
  }, [watchType]);
  return (
    <div>
      <form id="servicesettingsfrm" onSubmit={handleSubmit(onSubmitHandler)} noValidate>
        <div className="row">
          <div className="col-md-6">
            <div className="card card-default mb-4">
              <div className="card-header">Basic Details</div>
              <div className="card-body">
                <UiInput
                  label="Service Name"
                  type="text"
                  required={true}
                  name="name"
                  message={errors.name?.message}
                  {...register("name")}
                />
                <div className="d-flex flex-column flex-sm-row">
                  <UiToggle
                    id="servicestatus"
                    name="status"
                    title="Is Enabled?"
                    {...register("status")}
                  />
                  <div className="d-flex ms-0 ms-sm-3">
                    <UiToggle
                      id="ischeclistreq"
                      name="is_checklist_req"
                      title={`Is Checklist Completion Required?`}
                      {...register("is_checklist_req")}
                    />
                    <UiPopover msg="Is it mandatory to complete the checklist to complete the task?" />

                  </div>
                </div>
                <UiToggle
                  id="isrecurring"
                  name="recurring"
                  title="Is Recurring?"
                  {...register("recurring")}
                />
                {watchType == true && <>
                  <div className="row">
                    <div className="col-sm-7">
                      <UiSelect
                        name="frequency"
                        required={true}
                        label="Auto Task Creation Frequency"
                        message={errors.frequency?.message}
                        options={["Monthly", "Quarterly", "Yearly"]}
                        {...register("frequency")}
                      />
                    </div>

                  </div>

                  {watchFreq == 'Monthly' && (
                    <>
                      <div className="d-flex">
                        <div className="" style={{ width: '150px' }}>
                          <UiSelect
                            name="monthly_day"
                            required={true}
                            label="Creation Date"
                            message={errors.monthly_day?.message}
                            options={days}
                            {...register("monthly_day")}
                          />
                        </div>
                        <div className="px-2" style={{ paddingTop: '35px' }}>of every month</div>
                      </div>
                      <div className="d-flex">
                        <div className="" style={{ width: '150px' }}>
                          <UiSelect
                            name="monthly_due_day"
                            label="Due Date"
                            required={true}
                            message={errors.monthly_due_day?.message}
                            options={days}
                            {...register("monthly_due_day")}
                          />
                        </div>
                        <div className="px-2" style={{ paddingTop: '35px' }}>of every month</div>
                      </div>
                     
                    </>
                  )}
                  {watchFreq == 'Quarterly' && (
                    <>
                      <label className="mb-1">Creation Date</label>
                      <div className="d-flex">
                        <div className="" style={{ width: '150px' }}>
                          <UiSelect
                            name="quarterly_day"
                            required={true}
                            message={errors.quarterly_day?.message}
                            options={days}
                            {...register("quarterly_day")}
                          />
                        </div>
                        <div className="text-center" style={{ width: '50px', 'paddingTop': '9px' }}>of</div>
                        <div className="" style={{ width: '200px' }}>
                          <UiSelect
                            name="quarterly_month"
                            required={true}
                            noBlank={true}
                            message={errors.quarterly_month?.message}
                            options={["First", "Second", "Third"]}
                            {...register("quarterly_month")}
                          />
                        </div>
                        <div className="px-2" style={{ width: '250px', 'paddingTop': '9px' }}>
                          month of every quarter
                        </div>
                      </div>
                      <label className="mb-1">Due Date</label>
                      <div className="d-flex">

                        <div className="" style={{ width: '150px' }}>
                          <UiSelect
                            name="quarterly_due_day"
                            required={true}
                            message={errors.quarterly_due_day?.message}
                            options={days}
                            {...register("quarterly_due_day")}
                          />
                        </div>
                        <div className="text-center" style={{ width: '50px', 'paddingTop': '9px' }}>of</div>
                        <div className="" style={{ width: '200px' }}>
                          <UiSelect
                            name="quarterly_due_month"
                            noBlank={true}
                            required={true}
                            message={errors.quarterly_due_month?.message}
                            options={["First", "Second", "Third"]}
                            {...register("quarterly_due_month")}
                          />
                        </div>
                        <div className="px-2" style={{ width: '250px', 'paddingTop': '9px' }}>
                          month of every quarter
                        </div>
                      </div>

                   
                    </>
                  )}
                  {watchFreq == 'Yearly' && (
                    <>
                      <div className="d-flex">
                        <div className="" style={{ width: '150px' }}>
                          <Controller
                            name="yearly_daymonth"
                            control={control}
                            render={({
                              field: { onChange, onBlur, value, name, ref },
                              fieldState: { invalid, isTouched, isDirty, error },
                              formState,
                            }) => (
                              <UiDatePicker
                                label="Creation Date"
                                onChange={onChange}
                                default_val={value}
                                required={true}
                                message={errors.yearly_daymonth?.message}
                                dateFormat="dd-MMM"
                                ref={ref}
                                name={name}
                              />
                            )}
                          />
                        </div>
                        <div className="px-2" style={{ width: '200px', paddingTop: '35px' }}>of every year</div>
                      </div>
                      <div className="d-flex">
                        <div className="" style={{ width: '150px' }}>
                          <Controller
                            name="yearly_due_daymonth"
                            control={control}
                            render={({
                              field: { onChange, onBlur, value, name, ref },
                              fieldState: { invalid, isTouched, isDirty, error },
                              formState,
                            }) => (
                              <UiDatePicker
                                onChange={onChange}
                                label="Due Date"
                                default_val={value}
                                required={true}
                                message={errors.yearly_due_daymonth?.message}
                                dateFormat="dd-MMM"
                                ref={ref}
                                name={name}
                              />
                            )}
                          />


                        </div>
                        <div className="px-2" style={{ width: '200px', paddingTop: '35px' }}>of every year</div>
                      </div>
                      {pstate.calc.monthly_lastss &&
                        <div className="alert alert-info">
                          Task for {pstate.calc.fy} will be created on {format(parse(w_yearly_daymonth, 'dd-MMM', new Date()), 'dd MMM,')} {format(parse(w_yearly_daymonth, 'dd-MMM', new Date()), 'M') < 4 ? parseInt(pstate.calc.year1) + 1 : pstate.calc.year1}
                        </div>
                      }
                    </>
                  )}

                  {(watchFreq && pstate.note_msg) && <div className="alert alert-info">{pstate.note_msg}</div>}
                  {watchFreq &&

                    <>
                      <div className="border rounded p-3 mb-2">
                        <div className="form-check">
                          <input type="radio" className="form-check-input" value='last' name="current_period" id="lastperiodtask" {...register("current_period")} />
                          <label className="form-check-label" htmlFor="lastperiodtask">Create Tasks for the Recently Completed Period</label>
                        </div>
                        <p className="text-warning mb-0 mt-2">  

                        {watchFreq == 'Monthly' &&
                          <>
                            Example: Task for {pstate.calc.monthly_last} will be created on {w_monthly_day} {pstate.calc.monthly_on_cur}
                          </>
                        }
                        {watchFreq == 'Quarterly' &&
                          <>
                             Example: Task for {pstate.calc.quarter_last} will be created on {w_quarterly_day} {format(addMonths(new Date(2022, pstate.calc.q_month_cur, 1), w_quarterly_month == 'Second' ? 1 : w_quarterly_month == 'Third' ? 2 : 0), 'MMM')}, {pstate.calc.q_year_cur}
                          </>
                        }
                        {(watchFreq == 'Yearly' && w_yearly_daymonth) &&
                          <>
                             Example: Task for {isBefore(parse(w_yearly_daymonth+'-'+pstate.calc.q_year_cur, 'dd-MMM-yyyy', new Date()), new Date(pstate.calc.q_year_cur, 3, 1)) == true ? pstate.calc.fy_last : pstate.calc.fy_last1} will be created on {format(parse(w_yearly_daymonth, 'dd-MMM', new Date()), 'dd MMM,')} {pstate.calc.q_year_cur}
                          </>
                        }
                        </p>
                      </div>
                      <div className="border rounded p-3 mb-4">
                        <div className="form-check">
                          <input type="radio" className="form-check-input" value='current' name="current_period" id="currentperiodtask" {...register("current_period")} />
                          <label className="form-check-label" htmlFor="currentperiodtask">Create Tasks for the Currently Running Period</label>
                        </div>

                        <p className="text-warning mb-0 mt-2">  

                        {watchFreq == 'Monthly' &&
                          <>
                            Example: Task for {pstate.calc.monthly} will be created on {w_monthly_day} {pstate.calc.monthly_on_cur}
                          </>
                        }
                        {watchFreq == 'Quarterly' &&
                          <>
                             Example: Task for {pstate.calc.quarter} will be created on {w_quarterly_day} {format(addMonths(new Date(2022, pstate.calc.q_month_cur, 1), w_quarterly_month == 'Second' ? 1 : w_quarterly_month == 'Third' ? 2 : 0), 'MMM')}, {pstate.calc.q_year_cur}
                          </>
                        }
                        {(watchFreq == 'Yearly' && w_yearly_daymonth) &&
                          <>
                             Example: Task for {isBefore(parse(w_yearly_daymonth+'-'+pstate.calc.q_year_cur, 'dd-MMM-yyyy', new Date()), new Date(pstate.calc.q_year_cur, 3, 1)) == true ? pstate.calc.fy : pstate.calc.fy1} will be created on {format(parse(w_yearly_daymonth, 'dd-MMM', new Date()), 'dd MMM,')} {pstate.calc.q_year_cur}
                          </>
                        }
                        </p>
                      </div>
                    </>
                  }



                  <UiToggle
                    id="clientuserassign"
                    name="use_client_users"
                    title="Assign Auto Tasks to Users of Respective Clients"
                    {...register("use_client_users")}
                  />
                  {w_use_client_users == 0 &&
                    <Controller
                      name="users[]"
                      control={control}
                      render={({ field }) => (

                        <UiRSelect
                          {...field}
                          options={pstate.service.all_users}
                          className="ss"
                          isMulti
                          hideSelectedOptions={false}
                          message={errors.users?.message}
                          label="Assign Auto Tasks to (Users)"
                        />

                      )}
                    />
                  }
                </>
                }




                {pstate.service && (<input type="hidden" value={pstate.service.id} name="service"></input>)}


              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card card-default mb-4">
              <div className="card-header">Billing Settings</div>
              <div className="card-body">

                <div className="row">
                  <div className="col-6">
                    <UiInput
                      label="SAC Code"
                      type="text"
                      name="sac"
                      message={errors.sac?.message}
                      {...register("sac")}
                    />
                  </div>
                  <div className="col-6">
                    <UiSelect
                      name="gst"
                      label="GST %"
                      noBlank={true}
                      required={true}

                      message={errors.gst?.message}
                      options={["5%", "12%", "18%", "28%"]}
                      {...register("gst")}
                    />
                  </div>
                </div>



                <UiInput
                  label="Default Billing Rate (Excl. of Tax)"
                  type="text"
                  name="price"
                  icon="inr"
                  message={errors.price?.message}
                  {...register("price")}
                />

                <UiToggle
                  id="billable"
                  name="billable"
                  title="Mark its tasks billable by default"
                  {...register("billable")}
                />



              </div>
            </div>

            <div className="card card-default mb-4">
              <div className="card-header">GST API Config.</div>
              <div className="card-body">
                {watchType == 1 ? <>
                  <div className="d-flex">
                    <UiToggle
                      id="verifygst"
                      name="gst_api"
                      title={`Check GST return filing status from GST Server.`}
                      {...register("gst_api")}
                    />
                    <UiPopover msg="Automatically check the actual GST return filing status (From GST Server) at the time of completing the task." />

                  </div>

                  {w_gst_api == 1 &&
                    <Controller
                      name="gst_return"
                      control={control}
                      render={({ field }) => (

                        <UiRSelect
                          {...field}
                          options={pstate.service.return_types}
                          className="ss"
                          required={true}
                          hideSelectedOptions={false}
                          message={errors.gst_return?.message}
                          label="GST Return Type"
                        />

                      )}
                    />
                  }
                </>
                  : <p className="text-secondary">GST API configuration can be done only in recurring services.</p>}
              </div>
            </div>

            <div className="card card-default mb-4">
              <div className="card-header">Document Collection Request</div>
              <div className="card-body">

                <UiToggle
                  id="doc_req"
                  name="doc_req"
                  title="Create Document Collection Request Automatically?"
                  {...register("doc_req")}
                />
                {w_doc_req == 1 &&
                  <UiTextArea
                    required={true}
                    label="Doc. Collection Request Default Message"
                    name="req_msg"
                    message={errors.req_msg?.message}
                    {...register("req_msg")}
                  />
                }
              </div>
            </div>
          </div>
        </div>

        <UiButton loading={state["loader"]} title="Save" />
      </form>

    </div>
  )

}
export default function Service() {
  let { id } = useParams();
  let navigate = useNavigate();
  const queryClient = useQueryClient()
  const [state, setState] = useState({});
  const [loader, showLoader] = useState(false);

  const handleDelete = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        showLoader(true)
        instance
          .get("/update_service?delete=" + id)
          .then(function (response) {
            showLoader(false)
            if (response.data.status == 'success') {
              queryClient.invalidateQueries(['services'])
              navigate(-1);
              toast(response.data.msg, { type: "success" });
            } else {
              toast(response.data.msg, { type: "error" });
            }


          })

      }
    })


  }

  const fetchData = () => {
    instance
      .get("/service?id=" + id)
      .then(function (response) {
        setState({ ...state, ...response.data });
        if (response.data.status == 'success') {

        } else {
          toast(response.data.msg, { type: "error" });
        }


      })
      .catch(function (error) { })
      .then(function () { });

  }

  useEffect(() => {
    fetchData();
  }, []);

  if (state.status == 'error') {
    return (
      <PageError msg={state.msg} code={state.code} />
    )
  }


  return (
    <>
      <Helmet>

        {state.service && (
          <title>
            {state.service.name} | Services | Practive
          </title>
        )}

      </Helmet>
      <div>
        {state.service ? (
          <div>
            <PageHeader title={`${state.service.name} ${state.service.dsc ? '(DSC Service)' : ''}`} parent="Services" link="/services">
              <button className="btn btn-outline-danger" onClick={() => handleDelete(id)}>Delete</button>

            </PageHeader>


            <div className="tabs mb-3">

              <NavLink to="" end className="tab-itm">Settings</NavLink>
              <NavLink to="checklist" className="tab-itm">Checklist</NavLink>
              
              <NavLink to="custom-fields" className="tab-itm">Custom Fields</NavLink>
              {state.service.recurring == 1 && <NavLink to="clients" className="tab-itm">Clients <span className="badge bg-primary rounded-pill">{state.service.assigned_clients}</span></NavLink>}
            </div>

            <Outlet context={[state, setState]} />


          </div>
        ) : <BgLoader />}
        <OverlayLoader visible={loader} />

      </div>
    </>

  );
}
