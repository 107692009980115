import React, { useState, useEffect, forwardRef } from "react";
import { instance } from "../../axios";
import { TableZero, PageHeader, UiButton, UiRSelect, BgLoader, InLoader, UiDateRange, UiActioButton, PageError, UiInput, UiClientSelect } from "../../ui";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { rupees } from "../../helper";
import { Link, useLocation } from "react-router-dom"
import DataTable from 'react-data-table-component';
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { useQuery, useQueryClient, select } from "react-query";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useStore } from "../../state.js";
import { Helmet } from "react-helmet";
import queryString from 'query-string';
import { CSVLink, CSVDownload } from "react-csv";
import Swal from "sweetalert2";
import { toast } from "react-toastify";


export default function ReceiptsReport() {
  let navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient()
  const zstate = useStore((state) => state.clients);
  const setZState = useStore((state) => state.setClients);
  const [pagenum, setPage] = useState(1);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [tableDisable, setTableDisable] = useState(false);
  const parsed = queryString.parse(location.search);


  const schema = yup.object().shape(
    {

    }
  );

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    control,
    watch,
    formState,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });



  const onSubmitHandler = async (data) => {

    setPage(1)
    const obj = {};
    Object.entries(data).map(([key, val]) => {
      if (val) {
        obj[key] = Array.isArray(val) ? (val.map(itm => itm.value)).join(',') : val.label ? val.value + '::' + val.label : val
      }
    })

    const u = new URLSearchParams(obj).toString();

    if (location.search) {
      navigate("/receipts-report?" + u, { replace: true })
    } else {
      navigate("/receipts-report?" + u)
    }


  };



  const [loadedOptions, setOptions] = useState({});


  const client_w = watch('client');
  useEffect(() => {
    if (client_w == null) {
      setOptions({})
    }
  }, [client_w]);




  const headers = [
    { label: "Receipt No.", key: "receipt_id" },
    { label: "Date", key: "date" },
    { label: "Client", key: "name" },
    { label: "File No.", key: "file" },
    { label: "Group", key: "group_name" },
    { label: "Payment Mode", key: "mode" },
    { label: "Total Amount", key: "total" },
    { label: "Received Amount", key: "received_amount" },
    { label: "TDS Amount", key: "tds" },
    { label: "Discount", key: "discount" },
    { label: "Remark", key: "remarks" },
    { label: "Created By", key: "creator_name" },

  ];




  const [state, setState] = useState({});
  const [state1, setState1] = useState({});
  const [filter, setFilter] = useState({});

  const exportModal = useModal('export-all');
  const exportAll = () => {
    exportModal.show({ type: '/receipts_report', filter: window.location.search, header: headers, file: 'receipts-report.csv' }).then((res) => {
    });
  };

  async function fetchData(filter) {

    const { data } = await instance.get(
      "/receipts_report" + filter
    );

    var dataa = data;
    return dataa;
  }

  const defaultValues = {
    search: null,
    client: null,
    date: null,
    group: null,
    mode: null,
    entity: null,
  };

  const clearFilter = () => {
    reset(defaultValues)
    navigate("/receipts-report", { replace: true })
  }


  useEffect(() => {

    const parsed = queryString.parse(location.search);

    if (location.search) {
      //setPage(parseInt(parsed.page))
    } else {
      setPage(1)
      setFilter(defaultValues)
    }



  }, [location]);


  useEffect(() => {


    const parsed = queryString.parse(location.search);

    if (parsed.page) {
      setPage(parseInt(parsed.page))
    } else {
      // setPage(1)
    }

    setFilter(parsed)


  }, []);


  const columns = [
    {
      name: 'Receipt No.',
      selector: row => row.receipt_id,
      cell: row => (<Link to={`/view-receipt/${row.id}`}><b>{row.receipt_id}</b></Link>),
      sortable: true,
      sortField: 'receipt_id',
      id: 'receipt_id',
      width: '150px',

    },
    {
      name: 'Date',
      selector: row => row.date,
      sortable: true,
      sortField: 'date',
      id: 'date',
      width: '120px',
    },

    {
      name: 'Billing Entity',
      selector: row => row.entity,
      sortable: true,
      sortField: 'entity',
      id: 'entity',
      width: '160px',
      cell: row => (<span className="text-truncate" title={row.entity}>{row.entity}</span>),
    },

    {
      name: 'Client ',
      selector: row => row.name,
      sortable: true,
      sortField: 'name',
      id: 'name',
      width: '180px',
      cell: row => (<span className="text-truncate" title={row.name}><Link to={`/view-client/${row.cust_id}`}>{row.name}</Link></span>),
    },
    {
      name: 'File No. ',
      selector: row => row.file,
      width: '100px',
    },
    {
      name: 'Group',
      selector: row => row.group_name,
      width: '200px',
      cell: row => (<span className="text-truncate" title={row.group_name}>{row.group_name}</span>),
    },

    {
      name: 'Payment Mode ',
      selector: row => row.mode,
      sortable: true,
      sortField: 'mode',
      id: 'mode',
      width: '150px',
      cell: row => (<span className="text-truncate" title={row.mode}>{row.mode}</span>),
    },


    {
      name: 'Total Amount',
      sortable: true,
      sortField: 'total',
      id: 'total',
      selector: row => row.total,
      width: '150px',
      cell: row => (<span>{rupees(row.total)}</span>)
    },
    {
      name: 'Received Amount',
      sortable: true,
      sortField: 'received_amount',
      id: 'received_amount',
      selector: row => row.received_amount,
      width: '160px',
      cell: row => (<span>{rupees(row.received_amount)}</span>)
    },
    {
      name: 'TDS Amount',
      sortable: true,
      sortField: 'tds',
      id: 'tds',
      selector: row => row.tds,
      width: '150px',
      cell: row => (<span>{rupees(row.tds)}</span>)
    },
    {
      name: 'Discount',
      sortable: true,
      sortField: 'discount',
      id: 'discount',
      selector: row => row.discount,
      width: '150px',
      cell: row => (<span>{rupees(row.discount)}</span>)
    },
    {
      name: 'Remark',
  
      selector: row => row.remarks,
     // width: '150px',
      //cell: row => (<span>{rupees(row.total)}</span>)
    },

    {
      name: 'Created By',
      selector: row => row.creator_name,
      width: '160px',
      cell: row => (<span className="text-truncate" title={row.creator_name}>{row.creator_name}</span>),
    },

  ];




  async function fetchData1(id) {

    const { data } = await instance.get(
      "/filters?q=" + id
    );
    var dataa = data;
    return dataa;
  }
  const handlePageChange = (pg) => {

    setPage(pg)
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set('page', pg);
    if (pg) {
      if (location.search) {
        navigate('/receipts-report?' + currentUrlParams.toString(), { replace: true })
      } else {
        navigate('/receipts-report?' + currentUrlParams.toString())
      }


    }
  };

  const handleSort = (column, sortDirection) => {
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set('sort_by', column.sortField);
    currentUrlParams.set('sort', sortDirection);
    if (location.search) {
      navigate('/receipts-report?' + currentUrlParams.toString(), { replace: true })
    } else {
      navigate('/receipts-report?' + currentUrlParams.toString())
    }

  };


  const { data, error, isError, isLoading, isFetching } = useQuery(["receipts_report", location.search], () => fetchData(location.search), {
    keepPreviousData: true,
  });

  const { data: f_data, isLoading: f_is_loading } = useQuery(["filters", 'MGB'], () => fetchData1('MGB'), {
    keepPreviousData: true,
  });

  useEffect(() => {

    if (f_data) {
      const defaultValues1 = {
        search: filter.search,
        date: filter.date,
        client: filter.client && ({ value: parseInt(filter.client.split('::')[0]), label: filter.client.split('::')[1] }),
        group: f_data.groups && filter.group ? f_data.groups.filter(({ value }) => filter.group.split(',').includes(value.toString())) : null,
        mode: f_data.payment_modes && filter.mode ? f_data.payment_modes.filter(({ value }) => filter.mode.split(',').includes(value.toString())) : null,
        entity: f_data.billing_entities && filter.entity ? f_data.billing_entities.filter(({ value }) => filter.entity.split(',').includes(value.toString())) : null,
      };
      reset(defaultValues1)
    }
  }, [f_data, filter]);


  if (isLoading) {
    return <BgLoader />;
  }
  if (data.status == 'error') {
    return (
      <PageError msg={data.msg} code={data.code} />
    )
  }
  if (isError) {
    return <div>Error! {error.message}</div>;
  }

  return (
    <>
      <Helmet>
        <title>
          Receipts Report | Practive
        </title>
      </Helmet>
      <div>
        <div>
          <PageHeader title="Receipts Report" >
            {data.items &&
              <div className="btn-group1">
                <a style={{ cursor: 'pointer' }} data-bs-toggle="dropdown" aria-expanded="false" className={`btn btn-outline-primary d-inline-flex me-2 ${data.items.length == 0 && 'disabled'}`}>Export</a>
                <ul className="dropdown-menu dropdown-menu-end">
                  <li>
                    <CSVLink data={data.items} filename={"receipts-report.csv"} headers={headers} className="dropdown-item" >Export view</CSVLink>
                  </li>
                  <li>
                    <button className="dropdown-item" onClick={() => exportAll()}>
                      Export all
                    </button>
                  </li>
                </ul>
              </div>


            }

          </PageHeader>


          <form className="position-relative" onSubmit={handleSubmit(onSubmitHandler)} noValidate>
            {f_is_loading == true && <InLoader />}
            {f_data && (
              <div className="p-3 bg-light rounded mb-4 ">

                <div className="row">


                  <div className="col">
                    <label className="form-label">Date</label>
                    <Controller
                      name="date"
                      control={control}
                      defaultValue="All Time"
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState,
                      }) => (
                        <UiDateRange

                          onUpdate={onChange}
                          value={value}
                          ref={ref}
                          name={name}

                        />
                      )}
                    />
                  </div>
                  <div className="col-md-3">
                    <UiInput
                      label="Search"
                      type="text"

                      name="search"

                      {...register("search")}
                    />
                  </div>


                  <div className={`col-md-3`}>
                    <Controller
                      name="client"
                      control={control}
                      render={({ field }) => (

                        <UiClientSelect
                          {...field}
                  
                          className=""
                          message={errors.client?.message}
                          label="Client"
                        />

                      )}
                    />
                  </div>




                  <div className={`col-md-3 ${zstate.more_filter == true ? '' : 'd-none'}`}>
                    <Controller
                      name="mode[]"
                      control={control}
                      render={({ field }) => (

                        <UiRSelect
                          defaultValue={null}
                          options={f_data.payment_modes}
                          className=""
                          isMulti

                          label="Payment Mode"
                          {...field}
                        />

                      )}
                    />
                  </div>


                  <div className={`col-md-3 ${zstate.more_filter == true ? '' : 'd-none'}`}>
                    <Controller
                      name="group[]"
                      control={control}
                      render={({ field }) => (

                        <UiRSelect
                          defaultValue={null}
                          options={f_data.groups}
                          className=""
                          isMulti
                          message={errors.group?.message}
                          label="Group"
                          {...field}
                        />

                      )}
                    />
                  </div>


                  <div className={`col-md-3 ${zstate.more_filter == true ? '' : 'd-none'}`}>
                    <Controller
                      name="entity[]"
                      control={control}
                      render={({ field }) => (

                        <UiRSelect
                          defaultValue={null}
                          options={f_data.billing_entities}
                          className=""
                          isMulti
                          hideSelectedOptions={false}
                          message={errors.entity?.message}
                          label="Billing Entity"
                          {...field}
                        />

                      )}
                    />
                  </div>


                  <div className={`col ${zstate.more_filter == true ? '' : 'd-none'}`}>
                  </div>


                  <div className="col-md-2" style={{ paddingTop: '28px', minWidth: '200px' }}>
                    <div className="d-flex justify-content-end">
                      {zstate.more_filter == true ? (
                        <UiActioButton type="button" className="fs-3 text-dark" title="Less" tooltip="Hide More Filter" action={() => setZState({ ...zstate, more_filter: false })} />
                      ) : (

                        <UiActioButton type="button" className="fs-3 text-dark" title="More" tooltip="More Filter" action={() => setZState({ ...zstate, more_filter: true })} />
                      )}

                      <UiActioButton className="fs-3 text-muted" title="Clear" tooltip="Reset Filter" action={() => clearFilter()} />
                      <UiActioButton type="submit" className="fs-3" tooltip="Apply Filter" title="Go" />
                    </div>

                  </div>
                </div>



              </div>
            )}
          </form>

          <div className="row">
            <div className="col-md-3 mb-3">
              <div className="p-3 rounded bg-light border-dashed h-100 d-flex align-items-end">
                <div>
                  <span className="fs-3 lh-1">{rupees(data.stats.total)}</span>
                  <span className="mt-2 fw-semibold d-block text-muted">Total Amount</span>
                </div>
              </div>
            </div>
          </div>


          <div className="card card-default position-relative">
            {isFetching && <InLoader />}
            <div className="card-body fcolumn tablecard">

              <DataTable
                columns={columns}
                striped={true}
                pagination
                paginationServer
                paginationPerPage="20"
                paginationDefaultPage={pagenum}
                paginationResetDefaultPage={true}
                paginationTotalRows={data.total}
                clearSelectedRows={toggleCleared}
                onSort={handleSort}
                sortServer
                persistTableHead
                defaultSortFieldId={parsed.sort_by ? parsed.sort_by : null}
                defaultSortAsc={parsed.sort == 'desc' ? false : true}
                disabled={tableDisable}
                paginationComponentOptions={{ noRowsPerPage: true }}
                data={data.items}
                onChangePage={handlePageChange}
                noDataComponent={<TableZero title="Receipts" />}

              />


            </div>
          </div>
        </div>
      </div>
    </>
  );
}
